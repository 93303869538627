@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.order{
  height: 100vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.disabled-link{
}
.disabled-link:hover{
  background-color: rgb(196, 195, 195);
  opacity: 0.5;
  cursor: not-allowed;
  border-radius: 10px;
}


/* myToasts.css */
.myToast-container {
  position: absolute;
  top: 40px;
  left: 42%;
  transform: translateX(45%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
}

.myToast {
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: slideIn 0.5s forwards, fadeOut 0.5s 2.5s forwards; 
  width: 300px;
  height: 52px;
  padding: 12px;
  gap: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
  min-width: 300px;
}

.myToast .icon {
  font-size: 20px;
  margin-right: 10px;
}

.myToast .close-icon {
  margin-left: auto;
  cursor: pointer;
}
.myToast.info {
  background-color: #0059E1  ; /* Blue */
}

.myToast.success {
  background-color: #00893C  ; /* Green */
}

.myToast.error {
  background-color: #e74c3c; /* Red */
}

.myToast.warning {
  background-color: #f39c12; /* Orange */
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-50px) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

:root {
    --text-blue: #1D59F9;
    --text-gray: gray;
}

.p-14 {
    padding: 14px !important;
}

.btn-poin-gray {
    background-color: rgba(0, 0, 0, 0.05) !important;
    border: 0px !important;
    box-shadow: none !important;
}


.customise-program {
    input[type="radio"]+.custom-radio {
        border: 1.5px solid white;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15) inset;


    }

    input[type="radio"] {
        &:checked+.custom-radio {
            border: 1.5px solid var(--text-blue);
            box-shadow: none;


        }
    }
}

.focus-field {
    min-height: 64px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &:focus-visible {
        outline: none;
    }

    input {
        border: none !important;

        &:focus-visible {
            outline: none;
        }
    }

    textarea {
        border: none !important;

        &:focus-visible {
            outline: none;
        }
    }
}

.focus-field.active {
    border: 1px solid var(--text-blue);

}

.btn-group-reward {
    border: 1px solid var(--text-gray);

    button {
        background-color: transparent;
        border: none;
        width: 40px;
        height: 40px;
    }

    button.active {
        background-color: black;
        color: white;
    }
}

.compaign-detail-tab {
    box-shadow: 0px 0px 48px 0px #0000000A;

    ul {
        box-shadow: 0px 0px 2px 0px #0000001A inset;
        background: #F7F9FB;
        padding: 6px;
    }
}
.compaign-detail-tab-img{
    background: #F8F8F8;

}
.preview-img{
    --bs-modal-width: 620px !important;
}
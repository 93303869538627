.new-select-option {
    .stats_select {
        border: 1px solid #EFF0F6 !important;
        padding: 8px 10px;

        // div{
        //     border: none !important;
        // }
        .MuiSelect-select {
            padding: 0px !important;
        }

        fieldset {
            border: 0px !important;
        }
    }
}

.line-chart {
    .recharts-wrapper {
        width: 100% !important;
        // height: 80px !important;
    }
}

.white-border {
    background-color: white;
    border: 1.5px solid #EFF0F6;
}

.custom-table {
    .table-header-badges {
        background-color: #EFF0F6 !important;
    }

    .table-cell-1 {
        width: 230px;
        .MuiCheckbox-colorPrimary{
            padding: 3px 7px 3px 0px!important;
        }
    }

    .table-cell {
        width: 180px;
        .MuiFormControlLabel-label{
            font-size: 14px !important;
        }
    }
}
.new-order-linechart{
    .recharts-wrapper{
        width: 100% !important;
    }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .react-otp-input{
    input{
        &:focus{
            outline: none !important;
        }
    }
  }
  .onboarding-fields{
    input{
        &:focus-visible{
            outline: none !important;
        }
    }
  }
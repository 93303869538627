.Manrope {
    font-family: 'Manrope', sans-serif !important;
}
.business_admin_main{
.community {
    h1 {
        font-size: 62px;
    }

    input {
        box-shadow: 0px 20px 70px 0px rgba(0, 39, 96, 0.12);
    }

    button {
        font-family: 'Manrope', sans-serif !important;

    }
}

@media(max-width:1200px) {
    .community h1 {
        font-size: 58px;
    }
}

@media(max-width:992px) {
    .community h1 {
        font-size: 54px;
    }
}

@media(max-width:768px) {
    .community h1 {
        font-size: 48px;
    }
}

@media(max-width:576px) {

    .community h1 {
        font-size: 43px;
    }
}

@media(max-width:478px) {
    .community {
        h1 {
            font-size: 40px;
        }

        form {
            display: block;

            input {
                width: 100% !important;
            }

            button {
                margin-top: 10px;
                padding-top: 3px !important;
                padding-bottom: 3px !important;
            }
        }

    }
}

@media(max-width:425px) {}

@media(max-width:375px) {
    .community h1 {
        font-size: 36px;
    }
}

@media(max-width:320px) {}}
:root {
  --text-blue: #1D59F9;

}

.login-form {
  .input-group {
    span {
      background-color: transparent;
    }

    input {
      color: rgba(15, 23, 42, 1);

      &::placeholder {
        color: rgba(148, 163, 184, 1);
      }
    }
  }
}

.login-header {
  position: absolute;
  top: 12px;
  width: 100%;
  left: 0;
}

.business-login-form {
  box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);
  height: 510px;
  border: 1px solid #eaeaea;
}

.otp {
  >div {
    flex-wrap: wrap;
    justify-content: center;
  }

  input {
    margin-top: 15px;
    width: 58px !important;
    height: 58px !important;
    border: 1px solid var(--text-blue);
    color: var(--text-blue);
    border-radius: 8px;
    text-align: center;
    font-weight: 400;

    &:focus-visible {
      outline: none !important;
    }
  }
}

.forget-number-fieldGrater {
  input {
    border: 1.5px solid var(--text-blue) !important;
  }
}

.forget-number-fieldLower {
  input {
    border: 1.5px solid rgba(226, 232, 240, 1) !important;
  }
}

.forget-number-field {
  .PhoneInputCountryIcon--border {
    box-shadow: none !important;
    background: white !important;
    width: 22px;
    height: 22px;

    /* padding: 12px; */
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }
  }

  .PhoneInputCountryIcon {
    g {
      display: none;
    }
  }

  .PhoneInput .PhoneInputCountry {
    border-radius: 12px;
    border: 1.5px solid rgba(226, 232, 240, 1);
    padding: 0px 8px;
  }

  input:focus-visible {
    outline: none;
  }

  input {
    padding: 14px;
    background: white;
    font-weight: 700;
    border-radius: 12px;
    border: 1.5px solid rgba(226, 232, 240, 1);
    color: rgb(0, 0, 0);

    &:focus {
      border: none;
    }

    &::placeholder {
      color: rgba(148, 163, 184, 1);
    }
  }
}

.setting-page-phone-number {
  .PhoneInput .PhoneInputCountry {
    border-radius: 15px !important;
    width: 75px !important;
    border: 1px solid #dee2e6 !important;
    justify-content: center !important;
    padding: 0px 8px;
  }

  input {
    padding: 9px 12px !important;
    font-weight: 500 !important;
    height: 48px;
    border-radius: 15px !important;
    font-size: 14px;
    border: 1px solid #dee2e6 !important;
    color: #000000 !important;
    // color: rgb(200, 195, 201) !important;
  }
}

.login_height {
  min-height: 100vh;
}

.select-Address {
  .MuiAutocomplete-endAdornment {
    display: none !important;
  }

  input {
    font-weight: 500 !important;
  }

  >div {
    >div {
      >div {
        padding: 12px 12px !important;
      }
    }
  }

  div {
    border: none !important;

    div {
      fieldset {
        border: none !important;
        font-family: "Inter", sans-serif !important;

        &:hover {
          border: none !important;
        }
      }

      >div {
        border-radius: 50px !important;
      }
    }
  }
}

.Or-lines {
  white-space: nowrap;
}

.Or-lines:after,
.Or-lines:before {
  background-color: #E2E8F0;
  content: "";
  display: inline-block;
  height: 1px;
  width: -webkit-fill-available;
}

.just-border {
  border: 1.5px solid rgba(226, 232, 240, 1),
}

.signup-main {
  .login-header {
    position: static !important;
    top: 0 !important;
  }

  .business-login-form {
    height: auto !important;
  }

  .login_height {
    min-height: calc(100vh - 80px) !important;
  }
}

.signup-steps-pages {
  .login-header {
    position: static !important;
    top: 0 !important;
  }

  .login_height {
    min-height: auto !important;

  }

  .SignUp-progress-bar {
    .progress {
      height: 8px;
      background-color: #D0E2FF;

      .progress-bar {
        background-color: var(--text-blue);
      }
    }


  }

  .select-Address {
    input {
      font-weight: 400 !important;
      padding-left: 0px !important;

      &::placeholder {
        color: rgba(148, 163, 184, 1) !important;
        opacity: 1 !important;
      }
    }

    >div>div>div {
      padding: 10px 12px !important;
    }
  }

  .forget-number-field input {
    color: #94A3B8;
    font-weight: 500 !important;
  }
}

.getStarted-bg {
  input {
    &:focus {

      background-color: rgba(255, 255, 255, 0.2) !important;
    }
  }
}

.hero-section {
  input {
    background-color: transparent;
    border: 1.5px solid black !important;
    width: 50%;

    &:hover {
      box-shadow: none !important;
      border-color: black !important;

    }
  }

}

.bussiness-placeholder input::placeholder {
  color: #718EBF !important;
}

@media (min-width: 992px) {
  .auth-logo {
    position: absolute;
    top: 0;
  }
}

@media (max-width: 992px) {
  .login-header {
    position: static !important;
  }

  .business-login-form {
    height: auto;
  }

  .login_height {
    min-height: auto;
  }

  .signup-main {


    .login_height {
      min-height: auto !important;
    }
  }
}

@media(max-width:576px) {
  .hero-section {
    input {

      width: 100%;

    }

    button {
      width: 100% !important;
    }

  }
}
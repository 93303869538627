:root {
    --text-blue: #1D59F9;
   
  
  }
.super {
    .business-uploader {
        border: 2px dashed var(--text-blue);
        background-color: rgba(233, 239, 255, 1);
    }

    .invalidFile {
        border: 1.5px solid rgba(228, 29, 29, 1);
    }

    .image-uploader-card {
        border: 1.5px solid rgba(17, 175, 34, 1);

    }

    .outRight-select {
        div[role="button"] {
            padding-top: .5rem !important;
            padding-bottom: .5rem !important;
        }
    }

    .businessModal {
        ::-webkit-scrollbar {
            width: 7px !important;
        }

        ::-webkit-scrollbar-thumb {
            background: rgba(217, 217, 217, 1);
            border-radius: 10px !important;
        }

        ::-webkit-scrollbar-track {
            box-shadow: none !important;
            border-radius: 0px !important;
        }
    }

    .ProfileVisit table {
        font-family: 'Poppins', sans-serif !important;

        thead {
            tr {
                th {
                    background-color: rgba(243, 246, 249, 1);
                    color: rgba(173, 177, 181, 1) !important;
                    font-weight: 600 !important;
                    font-family: 'Poppins', sans-serif;

                    &:first-child {
                        border-top-left-radius: 6px !important;
                        border-bottom-left-radius: 6px !important;
                        color: rgba(28, 39, 49, 1) !important;
                    }

                    &:last-child {
                        border-top-right-radius: 6px !important;
                        border-bottom-right-radius: 6px !important;
                    }
                }
            }
        }

        tr {
            // background-color: #7474e7;

            td {
                border-bottom: 3px solid white !important;
            }
        }
    }

    .order-table table tr td {
        border-bottom: none !important;
    }

    .order-table table tbody tr:last-child td {
        border-bottom: none !important;
        padding-bottom: 0px !important;
    }

    .order-table table tbody td {
        padding-bottom: 0px !important;
    }

    .order-table table thead {
        border-bottom: none !important;

        th {
            border-bottom: none !important;
        }
    }

    .business-table-drop-down {
        button {

            background-color: transparent !important;

            &:after {
                display: none !important;
            }
        }
    }

    @media (min-width:1040px) {

        .mbl-nav {
            display: none !important;
        }

        .sidebar-desktop {
            width: 20% !important;
        }

        .main_row {
            display: flex;
        }
    }

    @media (max-width:1040px) {


        .main_row {
            display: block;
        }

        .sidebar-desktop {
            display: none;
        }

        .mbl-nav {
            display: block !important;
            z-index: 999;
        }


    }

    @media (max-width:992px) {


        .sidebar-mobile-if {
            width: 50% !important;


        }


    }

    @media(max-width:576px) {
        .sidebar-mobile-if {
            width: 70% !important;


        }

    }
}
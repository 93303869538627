:root {
  --text-blue: #1D59F9;
  // --primary: #2563EB;
  --dark-blue: #0328EE;
  --text-red: #ee0303;

}

#dark-blue {
  color: var(--dark-blue) !important;
}


.business_admin_main {
  .btn-blue-dashed {
    border: 1.5px dashed var(--text-blue) !important;
    color: rgba(200, 195, 201, 1) !important;
    background-color: transparent !important;
    color: var(--text-blue) !important;
    font-size: 19px;
    font-weight: 500 !important;

  }

  .devices-table {
    .mui-select-fieldTwo {
      position: relative;

      fieldset {
        border: none !important;
        font-family: "Inter", sans-serif !important;
      }

      div[role="combobox"] {
        padding: 6px 8px !important;
      }

      >div {
        background-color: white;
        border: 1.5px dashed rgba(200, 195, 201, 1);
        // color: rgba(200, 195, 201, 1) !important;
      }

      svg {
        display: none;
      }

      &::after {
        content: "\f107";
        position: absolute;
        color: rgba(200, 195, 201, 1);
        font-family: "fontawesome" !important;
        top: 25%;
        right: 12px;
      }
    }
  }

  .input-field {
    border: 1.5px solid rgba(200, 195, 201, 1) !important;
    // color: rgba(200, 195, 201, 1) !important;
    resize: none;

    &::placeholder {
      color: rgba(200, 195, 201, 1) !important;
    }
  }

  .input-field-red {
    border: 1.5px solid #FF0000 !important;
    color: rgba(200, 195, 201, 1) !important;
    resize: none;

    &::placeholder {
      color: rgba(200, 195, 201, 1) !important;
    }
  }

  .text-gray {
    color: rgba(200, 195, 201, 1) !important;
  }

  .text-dark {
    color: rgba(28, 39, 49, 1) !important;
  }

  .search-field-with-icon {
    .input-group {
      background-color: white;
      border-radius: 50px;
      border: 1.5px solid rgba(200, 195, 201, 1);
      // height: 40px;

      input {
        border-radius: 50px;

        &::placeholder {
          color: rgba(200, 195, 201, 1);
        }
      }

      span {
        background-color: transparent;
      }
    }
  }

  .device-table {
    .deviceTableLastTD {
      height: 145px !important;
    }

    tr {

      td,
      th {
        background-color: rgb(255, 255, 255);
        color: rgba(173, 177, 181, 1) !important;
        font-weight: 600 !important;
        font-family: "Poppins", sans-serif;
        border-bottom: none !important;

        &:first-child {
          border-top-left-radius: 15px !important;
          border-bottom-left-radius: 15px !important;
          color: rgba(28, 39, 49, 1) !important;
        }

        &:last-child {
          padding: 6px !important;
          border-top-right-radius: 15px !important;
          position: relative;
          border-bottom-right-radius: 15px !important;
        }
      }
    }

    input[type="checkbox"] {
      border: 2px solid red !important;
    }
  }

  .btn-redTwoSideRounded {
    color: white !important;
    border: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    font-family: "inter", sans-serif !important;
  }

  .bg-problem-red {
    background-color: red !important;
  }

  .bg-problem-orange {
    background-color: rgb(180, 128, 31) !important;
  }

  .bg-problem-yellow {
    background-color: rgb(173, 173, 32) !important;
  }

  .btn-fill-green {
    background-color: #00BD13 !important;
    color: white !important;
    border: 0px !important;
    font-family: "inter", sans-serif !important;
  }

  .btn-white-borderTwo {
    background-color: white !important;
    color: var(--text-blue) !important;
    font-weight: 700 !important;
    border: 2px solid var(--text-blue) !important;
    font-family: "Inter", sans-serif !important;
  }

  .btn-white-borderRed {
    background-color: white !important;
    color: var(--text-red) !important;
    font-weight: 700 !important;
    border: 2px solid var(--text-red) !important;
    font-family: "Inter", sans-serif !important;
  }


  .text-gray {
    color: rgba(200, 195, 201, 1) !important;
  }

  .text-dark {
    color: rgba(28, 39, 49, 1) !important;
  }

  .radius-bor {
    border-radius: 30px !important;
  }

  .btn-gray-border {
    background-color: transparent !important;
    color: rgba(29, 89, 249, 1) !important;
    border: 2px solid rgba(217, 217, 217, 1) !important;
    font-family: "Inter", sans-serif !important;
  }

  .btn-white {
    background-color: rgba(255, 255, 255, 0.993) !important;
    color: rgba(27, 79, 248, 1) !important;
    font-weight: 700 !important;
    border: 1px solid rgba(27, 79, 248, 1) !important;
    font-family: "Inter", sans-serif !important;
  }

  .devices-tag-add {
    div {
      button {
        &:last-child {

          // width: 0px !important;
          svg {
            display: none;
            // position: relative;
            // visibility: hidden;
            // height: 0px !important;
          }

          &::after {
            content: "\f107";
            // position: absolute;
            font-size: 17px !important;
            display: flex;
            color: rgba(200, 195, 201, 1);
            font-family: "fontawesome" !important;
            // right: 12px;
          }
        }
      }

      div {
        fieldset {
          border: 2px solid rgba(200, 195, 201, 1);
          font-family: "Inter", sans-serif !important;

          &:hover {
            border: 2px solid rgba(200, 195, 201, 1) !important;
            font-family: "Inter", sans-serif !important;
          }
        }

        >div {
          border-radius: 30px !important;
        }
      }
    }
  }

  .input-field {
    border: 1.5px solid rgba(200, 195, 201, 1) !important;
    // color: rgba(200, 195, 201, 1) !important;
    resize: none;

    &::placeholder {
      color: rgba(200, 195, 201, 1) !important;
    }
  }

  .mui-select-field {
    position: relative;
    z-index: 99;

    fieldset {
      border: none !important;
      font-family: "Inter", sans-serif !important;
    }

    >div {
      border: 1.5px solid rgba(200, 195, 201, 1);
      background-color: white;
    }

    svg {
      display: none;
    }

    &::after {
      content: "\f107";
      position: absolute;
      color: rgba(200, 195, 201, 1);
      font-family: "fontawesome" !important;
      top: 32%;
      right: 12px;
    }
  }

  .loyalty_select {

    .mui-select-field {


      >div {
        border: 2px solid rgba(200, 195, 201, 1) !important;
        color: black !important;

        >div {
          padding: 10px !important;
        }
      }
    }
  }


  .setting-page-field {
    >div {
      >div {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
      }
    }
  }

  .mui-select-fieldTwo {
    position: relative;

    fieldset {
      border: none !important;
      font-family: "Inter", sans-serif !important;
    }

    div[role="button"] {
      padding: 6px 10px !important;
    }

    >div {
      background-color: white;
      border: 1.5px solid rgba(200, 195, 201, 1);
      // color: rgba(200, 195, 201, 1) !important;
    }

    svg {
      display: none;
    }

    &::after {
      content: "\f107";
      position: absolute;
      color: rgba(200, 195, 201, 1);
      font-family: "fontawesome" !important;
      top: 50%;
      right: 12px;
      transform: translate(0px, -50%);
    }
  }

  .devices-table {
    .mui-select-fieldTwo {
      position: relative;

      fieldset {
        border: none !important;
        font-family: "Inter", sans-serif !important;
      }

      div[role="button"] {
        padding: 6px 8px !important;
      }

      >div {
        background-color: white;
        border: 1.5px dashed rgba(200, 195, 201, 1);
        // color: rgba(200, 195, 201, 1) !important;
      }

      svg {
        display: none;
      }

      &::after {
        content: "\f107";
        position: absolute;
        color: rgba(200, 195, 201, 1);
        font-family: "fontawesome" !important;
        top: 50%;
        right: 12px;
        transform: translate(0px, -50%);
      }
    }
  }

  .Device-main {
    .nav-pills {
      transform: translate(0px, 3px);

      .nav-link {
        color: rgb(200, 195, 201) !important;
        background-color: transparent !important;
        border: none !important;
        border-radius: 0 !important;
        padding: 0px 0px 10px 0px !important;
      }

      .nav-link.active {
        color: black !important;
        font-weight: 600;
        border: none;
        border-bottom: 5px solid var(--text-blue) !important;
      }
    }

    .device-card-data {
      border: 1px solid #EAEAEA;
      box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);
    }
  }

  .paiChart {
    .recharts-wrapper {
      width: 100% !important;
    }

    .recharts-legend-wrapper {
      width: 100% !important;
      left: 0 !important;
    }
  }

  .pricing-switch {

    /* Remove this container when use*/
    .component-title {
      width: 100%;
      position: absolute;
      z-index: 999;
      top: 30px;
      left: 0;
      padding: 0;
      margin: 0;
      font-size: 1rem;
      font-weight: 700;
      color: #888;
      text-align: center;
    }

    /* The switch - the box around the slider */
    .container-switch {
      width: 55px;
      height: 25px;
      position: relative;
    }

    /* Hide default HTML checkbox */
    .checkbox {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    }

    .switch {
      width: 100%;
      height: 100%;
      display: block;
      background-color: var(--text-blue);

      border-radius: 30px;
      cursor: pointer;
      transition: all 0.2s ease-out;
    }

    /* The slider */
    .slider {
      width: 18px;
      height: 18px;
      position: absolute;
      left: calc(50% - 14.2px - 10px);
      top: calc(50% - 9px);
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
      transition: all 0.2s ease-out;
      cursor: pointer;
    }

    .checkbox:checked+.switch {
      background-color: rgba(219, 230, 255, 1);
    }

    .checkbox:checked+.switch .slider {
      left: calc(50% - 20px / 2 + 15.9px);
      top: calc(50% - 18.8px / 2);
    }
  }

  .markIocn {
    color: #181059;
  }

  .support-Bill {
    background-image: url(../../assets//imges/subscriptionLeftSide.png);
    background-size: cover;
    // button{
    //   background-color: #8645FF !important;
    // }
  }

  .support_accordin_expend {
    background-color: var(--text-blue) !important;
    background-image: url(../../assets//imges/subscriptionRightSide.png);
    background-blend-mode: soft-light;
    background-size: cover;

    .accordin_angle {
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #8645FF;
    }

    .under_accordin_color {
      color: white;
    }

    button {
      background-color: #8645FF !important;
    }

    &::before {
      display: none !important;
    }
  }

  .support_accordin_close {
    .accordin_angle {
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F8F4FF;
    }

    .under_accordin_color {
      color: var(--text-blue);
    }

    button {
      background-color: #8645FF !important;
    }

    &::before {
      display: none !important;
    }
  }

  .compare-table-main {

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    .compare-table {
      border-radius: 0px !important;
      background-color: white;
      border: 1.5px solid #E6E9F5;
      overflow-x: scroll;

      table {
        background-color: transparent !important;

        thead {
          tr {
            th {
              vertical-align: middle !important;
              border-top: 0px !important;

              &:first-child {
                border-left: 0px !important;
              }

              &:last-child {
                border-right: 0px !important;
                background-color: #f3f5f7 !important;

              }
            }
          }
        }

        tbody {
          tr {
            &:last-child {
              td {
                border-bottom: 0px !important;
              }
            }

            td {
              &:first-child {
                border-left: 0px !important;
              }

              &:last-child {
                border-right: 0px !important;
                background-color: #f3f5f7 !important;
              }
            }
          }
        }

        td,
        th {
          vertical-align: middle !important;
          border: 1.5px solid rgba(230, 233, 245, 1);
        }
      }

      .table> :not(caption)>*>* {
        background-color: transparent !important;
      }
    }
  }




  .marketing-mbl-cover-content {
    transform: scale(.9);
    bottom: -3px;
    height: 66%;

    .bg-white {
      border-radius: 18px;
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;
    }
  }


  /* Add this to your styles */
}

.custom-backdrop {
  position: fixed;
  overflow: hidden;
}

.imag_crop_shadow {
  canvas {
    box-shadow: 1px 1px 3px gray;
  }
}

.support_form_modal.modal {
  --bs-modal-width: 70% !important;
}

.businessPlanModal.modal {
  --bs-modal-width: 90% !important;

  .btn-white-borderTwo {
    background-color: transparent !important;
    color: var(--text-blue) !important;

  }
}

.businessPlanModal {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .modal-content {
    height: 88vh;
    overflow-y: scroll;
  }

  .modal-body {
    padding: 0px !important;
  }

}

.modals_scroll {
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }

}

@media(max-width:992px) {
  .marketing-mbl-cover-content {

    height: 40%;
    bottom: 0px;

    .bg-white {
      border-radius: 75px;
      padding-top: 20px;

    }
  }
}

@media(max-width:768px) {
  .businessPlanModal {

    .modal-content {
      height: 90vh;
    }
  }


  .marketing-mbl-cover-content {

    height: 50%;

    .bg-white {
      border-radius: 60px;
    }
  }

}

@media(max-width:500px) {

  .marketing-mbl-cover-content {

    height: 60%;

    .bg-white {
      border-radius: 40px;
    }
  }

}

@media(max-width:400px) {

  .marketing-mbl-cover-content {

    height: 70%;
    bottom: -2px;

    .bg-white {
      border-radius: 40px;
    }
  }

}

@media(max-width:350px) {

  .marketing-mbl-cover-content {

    height: 80%;
    bottom: -3px;

    .bg-white {
      border-radius: 18px;
      border-bottom-left-radius: 35px;
    }
  }


}

.btn-fill-blue {
  background-color: #3867fa !important;
  border: 1.5px solid #3867fa !important;
  text-transform: capitalize !important;
  color: white !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
:root {
    --text-blue: #1D59F9;
    --primary: #2563EB;
    --text-light-blue: #99C3FF;
    --text-light-gay: #C8C3C9;
    --text-gay: #64748B;
    // -----------transition
    --tran2: all .2s ease;
    --tran3: all .3s ease;
    --tran4: all .4s ease;
    --tran5: all .5s ease;
}

.form-check-input:checked {
    background-color: var(--text-blue) !important;
}

// ::-webkit-scrollbar {
//     height: 0px;
//     width: 0px;
// }
html {
    overflow-x: hidden;
    overflow-y: auto;

}

body {
    overflow-y: auto;

    overflow-x: hidden;
    font-family: 'Inter', sans-serif !important;
    background-color: #f9f9f9 !important;
    padding: 0px !important;
    margin: 0px !important;
    box-sizing: border-box;
}

body.modal-open {
    height: 100vh !important;
}

.montserrat {
    font-family: 'Montserrat', sans-serif !important;
}

.inter {
    font-family: 'Inter', sans-serif !important;
}

.roboto {
    font-family: 'Roboto', sans-serif !important;
}

.Spartan {
    font-family: 'League Spartan', sans-serif !important;
}

.Nunito {
    font-family: 'Nunito', sans-serif;
}

.font-10 {
    font-size: 10px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-17 {
    font-size: 17px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-30 {
    font-size: 30px !important;
}

.font-32 {
    font-size: 32px !important;
}

.font-700 {
    font-weight: 700;
}

.font-800 {
    font-weight: 800;
}

.white-space-nowrap {
    white-space: nowrap;
}

.text-dark-gray {
    color: var(--text-gay);
}

.text-gray {
    color: var(--text-light-gay);
}

.form-control {
    &:focus {
        box-shadow: none !important;
    }
}

.text-blue {
    color: var(--text-blue);
}

.cursor-pointer {
    cursor: pointer;
}

.shadow {
    box-shadow: 0px 4px 24px 12px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
}

.colorPrimary {
    color: var(--primary) !important;
}

.blue {
    color: var(--text-blue) !important;
}

.light-blue {
    color: var(--text-light-blue);
}

.btn-compain-detail-btn {
    border: 1px solid #4CC204 !important;
    background: rgba(76, 194, 4, 0.50) !important;
}

.btn-compain-detail-pending {
    border: 1px solid #c2a604 !important;
    background: rgba(194, 166, 4, 0.5) !important;
}

.btn-compain-detail-rejected {
    border: 1px solid #c24004 !important;
    background: rgba(194, 17, 4, 0.5) !important;
}


.btn-blue {
    background-color: var(--text-blue) !important;
    color: white !important;
    border: 0px !important;
}

.disable_btn {
    background-color: #EBF0F6 !important;
    cursor: not-allowed !important;
    color: #B3C1D2 !important;
}

.btn-blueTwo {
    background-color: var(--primary) !important;
    color: white !important;
    border: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    align-self: center;
}

.btn-gray {
    background-color: #D6D6D6 !important;
    color: black !important;
    border: 0px !important;
}

.sidebar {

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }



    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(230, 230, 230);
        border-radius: 10px;
    }
}

.sidebar-mobile-else {
    width: 0% !important;
    position: fixed;
    left: 0;
    top: 0;
    background: white;
    transition: ease-in .2s;
    height: 100vh;
    transform: translate(-100px, 0px);

}

.sidebar-mobile-if {
    transition: ease-out .2s;
    width: 30% !important;
    position: fixed;
    left: 0;
    overflow-y: scroll;
    top: 0;
    background: white;
    height: 100vh;

}

// .businessOrder {
//     border-bottom: 1px solid #D8E0E8;
// }
.webkit-scroll {

    ::-webkit-scrollbar {
        width: 8px;
        // height: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-thumb {
        background-color: #F3F3F3;
        border-radius: 10px;
    }
}

.business_admin_main {
    $background-color_1: #D0E2FF;
    $background-color_2: white;

    .about-slider {
        >span {
            .MuiSlider-thumb {
                width: 20px !important;
                height: 6px !important;
                box-shadow: none !important;
                border-bottom-right-radius: 10px !important;
                border-top-right-radius: 10px !important;
                border-bottom-left-radius: 0px !important;
                border-top-left-radius: 0px !important;

                &::before {
                    box-shadow: none !important;
                }
            }

            span {
                &:first-child {
                    background-color: $background-color_1 !important;
                    opacity: 1 !important;
                    height: 6px !important;

                }

                &:last-child {

                    display: block;
                    background-color: $background-color_2 !important;

                    // &::before{
                    //     display: none !important;
                    // }
                    &:hover {
                        box-shadow: none !important;
                    }
                }

                &:nth-child(2) {
                    height: 6px !important;

                    background-color: $background-color_2 !important;
                    opacity: 1 !important;
                    border: 0px solid currentColor !important;
                }

                .MuiSlider-valueLabel {
                    height: auto !important;

                    &::before {
                        z-index: -1 !important;

                    }

                    .MuiSlider-valueLabelCircle {
                        height: auto !important;

                        span {
                            height: auto !important;

                        }
                    }
                }
            }

            span[aria-hidden="true"] {
                // display: none;
                color: var(--text-blue) !important;
                font-family: 'Inter' sans-serif !important;
                font-size: 12px !important;
                font-weight: 600 !important;
            }
        }
    }

    .sidebar {

        // border-top-right-radius: 28px;
        // border-bottom-right-radius: 28px;
        box-shadow: 0px 5px 20px 0px #0000000D;
        background-color: white;

        ul {
            li {
                text-decoration: none;
                list-style: none;
                margin-top: 6px;
                transition: var(--tran3);

                &:hover {
                    background-color: transparent;
                    // border-right: 3px solid var(--text-blue);
                    // border-radius: 12px;

                    a {
                        color: var(--text-blue);
                        background: #1B59F81A;

                    }
                }

                a {
                    transition: var(--tran3);
                    text-decoration: none;
                    color: black;
                    padding: 12px;

                    i {
                        min-width: 30px;
                        min-width: 33px;
                        font-size: 20px;
                    }

                    span {
                        font-weight: 500 !important;

                    }
                }

                a.active {
                    // border-right: 3px solid var(--text-blue);
                    color: var(--text-blue);
                    background: #1B59F81A;


                }

                .disabled-link {
                    color: grey;
                    pointer-events: none;
                    cursor: not-allowed;
                    padding: 12px;

                    i {
                        color: grey;
                        min-width: 30px;
                        min-width: 33px;
                        font-size: 20px;
                    }

                    span {
                        color: grey;
                        font-weight: 500 !important;
                    }
                }
            }
        }
    }

    .inputgroup1 {
        border: 2px dashed var(--text-blue);
        border-radius: 5px;
        background-color: #E9EFFF;

    }

    .w-lg-80 {
        width: 82% !important;

        input[type=checkbox] {
            border-color: var(--text-blue);
        }

        .loyalty_program {

            input[type=checkbox] {
                border-color: #C8C3C9 !important;
                accent-color: #C8C3C9 !important;
            }

            .form-check-input:checked {
                background-color: #C8C3C9 !important;
            }
        }
    }

    .order {
        .colorgray {
            color: #ACB7BD !important;
        }

        .colorskyblue {
            color: #8ED1FF;
        }

        .colorpruppl {
            color: #FFCDEA;
        }

        .coloryellow {
            color: #FFAA00;
        }

        .colorred {
            color:
                #FF0000;
        }

        .inputgrouptwo {
            border: 1px dashed var(--text-blue);
        }

        .inputgrouptwo::placeholder {
            color: var(--text-blue) !important;
        }

        .Action {
            border: none !important;
            border-bottom: 2px solid #D9D9D9;
        }

        .accordionone {
            border: 1px solid #D9D9D9 !important;
            border-radius: 15px !important;
        }

        .accordion-button:focus {
            z-index: 3;
            /* border-color: var(--bs-accordion-btn-focus-border-color); */
            outline: 0;
            box-shadow: none !important;
        }

        .form-select:focus {
            border-color: #86b7fe;
            outline: 0;
            box-shadow: none !important;
        }





        .point {
            cursor: pointer;
        }

        .btn-white {
            background-color: rgba(255, 255, 255, 0.993) !important;
            color: var(--text-blue) !important;
            font-weight: 700;
            border: 1px solid var(--text-blue) !important;
            font-family: 'Inter', sans-serif !important;
        }

        .btn-green {
            background-color: rgba(255, 255, 255, 0.993) !important;
            color: #00BD13 !important;
            font-weight: 700 !important;
            border: 1px solid #00BD13 !important;
            font-family: 'Inter', sans-serif !important;
        }

        .btn-activgareen {
            background: linear-gradient(0deg, #4CC204, #4CC204),
                linear-gradient(0deg, rgba(76, 194, 4, 0.5), rgba(76, 194, 4, 0.5)) !important;
            color: #000000 !important;
            font-weight: 700 !important;
            border: 1px solid #4CC204 !important;
            font-family: 'Inter', sans-serif !important;
        }

        .btn-Pending {
            background-color: rgba(255, 255, 255, 0.993) !important;
            color: #FF9900 !important;
            font-weight: 700 !important;
            border: 1px solid #FF9900 !important;
            font-family: 'Inter', sans-serif !important;
        }

        .backgroundgray {
            background: #F5F5F5;
            border-radius: 8px;
        }

        .btn-Rejected {
            background-color: rgba(255, 255, 255, 0.993) !important;
            color: #FF3D3D !important;
            font-weight: 700 !important;
            border: 1px solid #FF3D3D !important;
            font-family: 'Inter', sans-serif !important;
        }

        .btn-Draft {
            background-color: rgba(255, 255, 255, 0.993) !important;
            color: #D9D9D9 !important;
            font-weight: 700 !important;
            border: 1px solid #D9D9D9 !important;
            font-family: 'Inter', sans-serif !important;
        }

        .btn-Report {
            color: #1D59F9;
            background-color: #FFFFFF;
            border: 2px solid var(--text-blue);
        }

        .mybox {
            background-color: #FFFFFF;
            border: 1px solid #00000033;
        }

        .profile {
            height: 50px;
            width: 50px;
        }



        .trip-mycard {
            background-color: #FFFFFF;
            border: 1px solid #E9EDF7;
            border-radius: 15px;
        }



        .selectfeild {
            background-color: #FFFFFF;
            border: 2px dashed #C8C3C9;
            border-radius: 20px;
        }


        .colorPrimary {
            color: var(--primary);
        }

        .bgcolor {
            background: #FFFFFF;
            border: 1px solid #EAEAEA;
            border-radius: 12px;
        }

        .modal {
            --bs-modal-width: 30% !important;
            --bs-border-radius-lg: 2.5rem !important;
        }

        .modaltwo {
            --bs-modal-width: 50% !important;
            --bs-border-radius-lg: 1.5rem !important;
        }

        .progress {
            --bs-progress-height: 10px !important;
            --bs-progress-bg: #D0E2FF !important;
            --bs-progress-bar-color: #D0E2FF !important;
            --bs-progress-bar-bg: var(--text-blue) !important;
        }



        tbody {
            tr {
                td {
                    border-bottom: none !important;
                }
            }
        }

        .order {
            height: 100vh;
        }


        // .not-active-Campaigns {
        //     border: 1px solid #EAEAEA;

        //     background: #FFF;

        //     box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);
        // }

        .Campaigns {
            .campaign-border-active {
                border: 1px solid #01BD13;

            }

            .campaign-border-pending {
                border: 1px solid #FFB240;

            }

            .campaign-border-reject {
                border: 1px solid #FF3D3D;

            }

            .campaign-border-draft {
                border: 1px solid #D9D9D9;

            }

            .campaign-card {
                background-color: white;
                box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);

                .campaigns-card-bar {
                    background-color: var(--primary);
                    width: 90%;

                    .progress {
                        background-color: rgb(255 255 255 / 20%) !important;

                        .progress-bar {
                            background-color: white !important;
                        }
                    }

                }
            }
        }

        .nav-pills .nav-link {
            color: #C2C2C2 !important;
            background-color: transparent !important;
            border-radius: 0px !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            border-bottom: 3px solid white !important;


        }


        .nav-pills .nav-link.active {
            color: var(--text-blue) !important;
            background-color: transparent !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;

            border-bottom: 3px solid var(--text-blue) !important;
            font-weight: 600 !important;
        }

        .marketing_tab {

            .nav-pills .nav-link {
                color: black !important;
                font-weight: 400 !important;
                font-size: 12px !important;
                border-bottom: 3px solid #F7F7F7 !important;

            }

            .nav-pills .nav-link.active {
                color: black !important;
                font-weight: 400 !important;
                border-bottom: 3px solid var(--text-blue) !important;

            }
        }

        .tab-nav-pills {
            #pills-tab {

                border-bottom: 1px solid #EAEAEA;
            }
        }

        .inputgroup {
            background-color: #FFFFFF;
            border: 2px solid #D9D9D9;
            border-radius: 12px;
            padding: 10px;

            &::placeholder {
                color: var(--text-light-gay);
            }

            input {

                &::placeholder {
                    color: var(--text-light-gay);
                }
            }
        }

        .inputgroup::placeholder {
            color: #C8C3C980;
            font-size: 14px;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
        }

        .bg-color {
            background-color: #FFFFFF;
            border: 1px solid #EAEAEA;
            box-shadow: 0px 21px 94px 0px #00000008;

        }


        .colorblack {
            color: #0F0F0F;
        }

        .colorblue {
            color: #483EA8;
        }

        .stepper-bars {
            .progress-bar {
                height: auto;

            }

            .progress-bar::before {

                content: none !important;
            }
        }



        .loyalty {
            background: radial-gradient(closest-side, #fff 79%, transparent 80% 100%), conic-gradient(#FF6826 calc(var(--progress-value) * 1%), #F0F0F0 0);
            animation: html-progress 2s 1 forwards;
        }

        .loyalty::before {
            animation: html-progress 2s 1 forwards;
        }

        .loyaltyone {
            background: radial-gradient(closest-side, white 79%, transparent 80% 100%, white 0), conic-gradient(#58CDFF calc(var(--progress-value) * 1%), #F0F0F0 0);
            animation: loyaltyone-progress 2s 1 forwards;
        }

        .loyaltyone::before {
            animation: loyaltyone-progress 2s 1 forwards;
        }

        .loyaltytwo {
            background: radial-gradient(closest-side, white 79%, transparent 80% 100%, white 0), conic-gradient(#7D39B2 calc(var(--progress-value) * 1%), #F0F0F0 0);
            animation: loyaltytwo-progress 2s 1 forwards;
        }

        .loyaltytwo::before {
            animation: loyaltytwo-progress 2s 1 forwards;
        }

        .loyaltythree {
            background: radial-gradient(closest-side, white 79%, transparent 80% 100%, white 0), conic-gradient(#61EAA8 calc(var(--progress-value) * 1%), #F0F0F0 0);
            animation: loyaltytwo-progress 2s 1 forwards;
        }

        .loyaltythree::before {
            animation: loyaltytwo-progress 2s 1 forwards;
        }

        h2 {
            text-align: center;
        }

        progress {
            visibility: hidden;
            width: 0;
            height: 0;
        }
    }

    .Surveys {
        .surveys {
            background-color: #FFFFFF;
            box-shadow: 0px 21px 94px 0px #00000008;
            border: 1px solid #EAEAEA;
            border-radius: 10px;
        }

        .surveysone {
            background-color: #FFFFFF;
            box-shadow: 0px 21px 94px 0px #00000008;
            border: 1px solid #EAEAEA;
            border-radius: 10px;
        }

        .Created {
            border-bottom: 1px solid #EAEAEA;
        }

        .Setting {
            border: 1px solid #1D59F9;
            background-color: #DDE6FE;

        }

        .Choices {
            background-color: #F9FAFB;
            border-radius: 10px;
        }

        .btn-Question {
            background-color: transparent;
            color: #CECACF;
            border: 1px solid #CECACF;
        }

        .btn-skywhite {
            background-color: #ECF2FF !important;
            border: none !important;
            color: var(--text-blue) !important;
        }

        .nav-pills .nav-link {
            color: #000000 !important;
            background-color: transparent !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-bottom: none !important;
            font-size: 16px !important;
            font-weight: 600 !important;
        }

        .nav-pills .nav-link.active {
            color: var(--text-blue) !important;
            background-color: transparent !important;
            border-bottom: 3px solid var(--text-blue) !important;
        }

        .tab-nav-pills #pills-tab {
            border-bottom: none !important;
        }

        .inputgroupon {
            background-color: white;
            border: 1.5px solid var(--text-blue);
            padding: 6px;

            input {

                &::placeholder {
                    color: var(--text-blue);
                }
            }
        }

        .inputgroupon::placeholder {
            color: #B3B1BB;
            font-size: 14px;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
        }

        iframe {
            // width: 100%;
            height: 100vh;
            border: none;
            /* Remove border */
            /* Hide WebKit scrollbar */
            overflow: hidden;
            scrollbar-width: none;
            /* Firefox */
            -ms-overflow-style: none;
            /* IE 10+ */
        }

        /* Hide scrollbar for WebKit browsers */
        iframe::-webkit-scrollbar {
            display: none;
        }
    }

    .login {
        input {
            &:focus {
                border-color: lightgray;
                box-shadow: none !important;
            }
        }

        .login-btn {
            padding: 6px 30px;
            border: none;
            background-color: #ADD8E6;
            border-radius: 6px;
        }
    }

    .order {
        .dots {
            vertical-align: middle;
            color: var(--text-light-gay);
            font-size: 25px;
        }

        .page-link {
            border: 1px solid var(--text-light-gay) !important;
            color: var(--text-light-gay) !important;
            margin: 5px;
            border-radius: 6px !important;

            &:focus {
                color: var(--text-blue) !important;
                box-shadow: none !important;

                background-color: transparent !important;
                border-color: var(--text-blue) !important;
            }

            &:hover {
                background-color: transparent !important;
                color: var(--text-blue) !important;
                border-color: var(--text-blue) !important;
            }
        }

        .page-link.active {
            background-color: transparent !important;
            border-color: var(--text-blue) !important;
            color: var(--text-blue) !important;

        }
    }

    .circular-progress {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        h6 {
            font-family: 'Montserrat', sans-serif !important;
            font-weight: 700 !important;
        }
    }

    .inner-circle {
        position: absolute;
        width: calc(90px - 25px);
        height: calc(90px - 25px);
        border-radius: 50%;
        background-color: lightgrey;
    }

    .percentage {
        position: relative;
        font-size: var(--font-size);
        color: rgb(0, 0, 0, 0.8);
    }

    .circular-progress1 {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .inner-circle1 {
        position: absolute;
        width: calc(80px - 30px);
        height: calc(80px - 30px);
        border-radius: 50%;
        background-color: lightgrey;
    }

    .percentage1 {
        position: relative;
        font-size: var(--font-size);
        color: rgb(0, 0, 0, 0.8);
    }

    .login-form {
        .input-group {
            border: 1.5px solid rgba(226, 232, 240, 1);

            span {
                background-color: transparent;
            }

            input {
                color: rgba(15, 23, 42, 1);
                padding: 12px;

                &::placeholder {
                    color: rgba(148, 163, 184, 1);
                }

                &:focus {
                    box-shadow: none !important;
                    color: rgba(148, 163, 184, 1);
                }
            }
        }
    }

    .login-header {
        position: absolute;
        top: 12px;
        width: 100%;
        left: 0;
    }

    .business-login-form {
        box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);
        height: 510px;
        border: 1px solid #EAEAEA;
    }

    .otp {
        >div {
            flex-wrap: wrap;
            justify-content: center;
        }

        input {
            margin-top: 15px;
            width: 58px !important;
            height: 58px !important;
            border: 1px solid rgba(37, 99, 235, 1);
            color: rgba(37, 99, 235, 1);
            border-radius: 8px;
            text-align: center;
            font-weight: 400;

            &:focus-visible {
                outline: none !important;
            }
        }
    }

    .forget-number-field {
        .PhoneInputCountryIcon--border {
            box-shadow: none !important;
            background: white !important;

            /* padding: 12px; */
            img {
                object-fit: cover;
                width: 22px;
                height: 22px;
                border-radius: 30px;
            }
        }

        .PhoneInputCountryIcon {
            g {
                display: none;
            }
        }

        .PhoneInput .PhoneInputCountry {
            border-radius: 12px;
            border: 1.5px solid rgba(226, 232, 240, 1);
            padding: 0px 8px;
        }

        input:focus-visible {
            outline: none;
        }

        input {
            padding: 14px;
            background: white;
            font-weight: 700;
            border-radius: 12px;
            border: 1.5px solid rgba(226, 232, 240, 1);
            color: rgba(15, 23, 42, 1);

            &:focus {
                border: 1.5px solid var(--text-blue);

            }

            &::placeholder {
                color: rgba(148, 163, 184, 1);
            }
        }
    }

    .login_height {
        min-height: 100vh;
    }

    .otp-verify {
        input {
            border: 1px solid var(--text-blue) !important;
            width: 70px;
            height: 70px;

            &:focus {
                border: 2px solid var(--text-blue) !important;
            }

            &:focus-visible {
                outline: none !important;
            }
        }
    }

    .table-mui {
        table {


            thead {
                th {
                    color: #7D7D7D !important;
                    font-family: 'Roboto', sans-serif !important;
                    font-size: 14px !important;
                    font-weight: 500 !important;
                }
            }

            tbody {
                tr {
                    td {
                        vertical-align: middle !important;
                        color: #7D7D7D !important;
                        font-family: 'Roboto', sans-serif !important;
                        font-size: 13px !important;

                        &:first-child {
                            font-size: 15px;
                            color: #323C47 !important;
                            font-weight: 500;
                        }

                        .dropdown-menu {

                            --bs-dropdown-min-width: auto !important;
                        }

                        .dropdown-toggle::after {
                            content: none !important;
                        }
                    }
                }
            }
        }
    }

    // ============= login & signup ==========
    .bg-tranparent {
        background-color: transparent;
    }

    .just-border {
        border: 1.5px solid rgba(226, 232, 240, 1),
    }

    .Or-lines {
        white-space: nowrap;
    }

    .Or-lines:after,
    .Or-lines:before {
        background-color: #E2E8F0;
        content: "";
        display: inline-block;
        height: 1px;
        width: -webkit-fill-available;
    }

    .signup-admin {
        input[type='checkbox'] {
            transform: scale(1.4);
            border-color: #E2E8F0;
        }
    }

    .order-progress-bar {
        height: 90px;
        width: 90px;

        svg {
            overflow: visible !important;
        }

        .CircularProgressbar {
            .CircularProgressbar-trail {
                stroke-width: 12 !important;
                stroke: var(--text-light-blue) !important;
            }

            .CircularProgressbar-path {
                stroke-width: 12 !important;
                stroke: var(--text-blue) !important;
            }

            .CircularProgressbar-text {
                font-family: 'Montserrat', sans-serif !important;
                font-weight: 700;
                font-size: 18px !important;
                fill: var(--text-blue);
            }
        }

    }

    .order-status-bar {
        height: 70px;
        width: 70px;

        .CircularProgressbar {
            .CircularProgressbar-trail {
                stroke-width: 18 !important;
                stroke: var(--text-light-blue) !important;
            }

            .CircularProgressbar-path {
                stroke-width: 18 !important;
                stroke: var(--text-blue) !important;
            }
        }
    }

    .order-progress-marketing-Impressions {
        height: 90px;
        width: 90px;

        svg {
            overflow: visible !important;
        }

        .CircularProgressbar {
            .CircularProgressbar-trail {
                stroke-width: 12 !important;
                stroke: #F0F0F0 !important;
            }

            .CircularProgressbar-path {
                stroke-width: 12 !important;
                stroke: #FF6826 !important;
                stroke-linecap: square !important;

            }

            .CircularProgressbar-text {
                font-family: 'Inter', sans-serif !important;
                font-weight: 700;
                font-size: 18px !important;
                fill: black;
            }
        }

    }

    .order-progress-click {
        .CircularProgressbar {
            .CircularProgressbar-path {
                stroke: #58CDFF !important;

            }
        }
    }

    .order-progress-redeems {
        .CircularProgressbar {
            .CircularProgressbar-path {
                stroke: #7D39B2 !important;

            }
        }
    }

    .order-progress-ctr {
        .CircularProgressbar {
            .CircularProgressbar-path {
                stroke: #61EAA8 !important;

            }
        }
    }

    .container-stepOne {
        /* The switch - the box around the slider */

        width: 54px;
        height: 27px;
        position: relative;

        /* Hide default HTML checkbox */
        .component-title {
            width: 100%;
            position: absolute;
            z-index: 999;
            top: 30px;
            left: 0;
            padding: 0;
            margin: 0;
            font-size: 1rem;
            font-weight: 700;
            color: #888;
            text-align: center;
        }


        .checkbox {
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;
        }

        .switch {
            width: 100%;
            height: 100%;
            display: block;
            background-color: #e9e9eb;
            border-radius: 16px;
            cursor: pointer;
            transition: all 0.2s ease-out;
        }

        /* The slider */
        .slider {
            width: 22px;
            height: 22px;
            position: absolute;
            left: calc(50% - 23px/2 - 11px);
            top: calc(50% - 22px/2);
            border-radius: 50%;
            background: #FFFFFF;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
            transition: all 0.2s ease-out;
            cursor: pointer;
        }

        .checkbox:checked+.switch {
            background-color: #e9e9eb;
        }

        .checkbox:checked+.switch .slider {
            left: calc(50% - 22px/2 + 11px);
            top: calc(50% - 23px/2);
        }

    }

    .step-three {
        .step-three-card {
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
        }

        .container-stepOne {
            width: 43px;
            height: 20px;

            label {

                border: 1px solid var(--text-blue);

            }

            .slider {
                width: 16px;
                height: 16px;
                left: calc(50% - 16px/2 - 11px);
                top: calc(50% - 16px/2);
                border-radius: 50%;
                background-color: var(--text-blue);

            }

            .checkbox:checked+.switch {
                background-color: var(--text-blue);
            }

            .checkbox:checked+.switch .slider {
                left: calc(50% - 16px/2 + 11px);
                top: calc(50% - 16px/2);
                background-color: white;

            }
        }
    }

    .tippy-arrow {
        color: white !important;
        // background-color: white !important;

    }

    .hireexpert ul li button.Mui-selected {
        background: var(--text-blue) !important;
        color: #ffffff;
    }

    .hireexpert ul li button {
        font-family: "Inter", sans-serif !important;

        font-weight: 600 !important;
        background-color: #ffffff !important;
        border: 1px solid #bebebe !important;
    }



    .AutocompleteGoggle {
        input {
            color: #94A3B8 !important;

            &:focus-visible {

                outline: none !important;
            }

            &::placeholder {
                color: #94A3B8 !important;
            }
        }
    }

    .ProfileVisit table tr td {
        border-bottom: 3px solid white !important;
    }

    .reactPaginate li a {
        min-width: 35px;
        min-height: 35px;
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 0px 6px;
        background-color: white;
        color: black;
        border: 1px solid #bebebe;
        border-radius: 6px;
        margin: 4px;
        font-weight: 500;
        font-family: "Poppins", sans-serif !important;
    }

    .reactPaginate li.active a {
        background-color: #f16336;
        color: white;
        border: 1px solid #f16336;
    }

    .reactPaginate li.active a:hover {
        color: white;
    }

    .reactPaginate ul {
        margin: 20px 0px !important;
        flex-wrap: wrap;

        .previous {
            a {

                color: #bebebe;
            }
        }

        .next {
            a {

                color: #bebebe;
            }

        }
    }

    .setting-page {
      


        .Setting {
            .nav-pills .nav-link.active {
                background-color: white !important;
                border: 1.5px solid var(--text-blue) !important;


            }

            .nav-pills .nav-link {
                border-radius: 30px !important;
                color: black !important;
                background-color: transparent !important;
                border: 1.5px solid white !important;
                padding-top: 12px !important;
                padding-bottom: 12px !important;
                // border-bottom: none !important;
                font-size: 15px !important;
                font-weight: 500 !important;
            }

            .setting-main-tab {

                .nav-link.active {
                    color: var(--text-blue) !important;
                    // background-color: white !important;
                    border: none !important;

                    &:after {
                        content: '';
                        display: flex;
                        width: 100%;
                        height: 3px;
                        background-color: var(--text-blue) !important;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                    }
                }

                .nav-link {
                    position: relative;
                    border-radius: 0px !important;
                    color: #718EBF !important;
                    background-color: transparent !important;
                    border: none !important;
                    padding-top: 12px !important;
                    padding-bottom: 12px !important;
                    border-bottom: 1.5px solid #F4F5F7 !important;
                    font-size: 15px !important;
                    font-weight: 500 !important;
                    &:focus{
                        box-shadow: none !important;
                    }
                }
            }


        }

        .sms-automation {
            border-top: 1px solid #1D59F9;
            border-bottom: 1px solid #1D59F9;
            background-color: #DDE6FE;
        }

    }

    .sms_automation_accordion {
        box-shadow: none !important;
        border: 1.5px solid #D9D9D9 !important;

        &::before {
            display: none !important;
        }

        textarea {
            border: 1.5px solid #D9D9D9 !important;
            resize: none !important;

            &:focus-visible {
                outline: none !important;
            }
        }

        .radio-form-control {
            .MuiFormControlLabel-label {
                font-size: 11px !important;
            }
        }
    }

    .table-dropdown {
        .dropdown-toggle {
            &::after {
                display: none !important;
            }
        }
    }

    .datatable-show-dropdown {
        >div {
            overflow-x: visible !important;
            overflow-y: visible !important;
        }

        .dropdown-menu {
            --bs-dropdown-min-width: auto !important;
        }

        input[type='checkbox'] {
            // border-color: var(--text-blue) !important;
            transform: scale(1.2) !important;
        }

        .rdt_TableBody {
            div[role='row'] {
                border-bottom: none !important;
            }

        }
    }


    .linkSetting {
        border-left: 2px solid rgba(200, 195, 201, 1);
    }

    .daterangepicker {
        background-color: #F5F5F5 !important;

        &::after {
            border-bottom: 6px solid rgba(200, 195, 201, 1) !important
        }

        .ranges {
            ul {
                li {
                    font-family: 'Inter', sans-serif !important;
                    font-weight: 500 !important;
                }

                li.active {
                    background-color: rgba(0, 71, 255, 0.1) !important;
                    color: var(--text-blue) !important;
                }
            }
        }

        .drp-calendar.left {
            .calendar-table {
                // background-color: transparent !important;
                // border: none !important;

                border-radius: 1rem !important;
                font-family: 'Inter', sans-serif !important;
                // .prev.available {
                //     background: var(--text-light-blue) !important;
                //     border-radius: 30px !important;
                //     height: 32px !important;

                // }
            }
        }

        .drp-calendar.right {
            .calendar-table {
                // background-color: transparent !important;
                // border: none !important;
                border-radius: 1rem !important;

                font-family: 'Inter', sans-serif !important;
                // .next.available {
                //     background: var(--text-light-blue) !important;
                //     border-radius: 30px !important;
                //     height: 32px !important;

                // }
                tbody {
                    td {}

                    // td.off{
                    //     background-color: transparent !important;
                    //     color: var(--text-gay);
                    // }
                    // td.in-range{
                    //     background-color: rgba(216, 227, 255, 1) !important;
                    // }
                }
            }
        }
    }

    .login-terms {
        position: relative;
        display: inline-block;
        transform: scale(1.4);
    }

    .login-terms::before {
        content: '';
        /* Unicode checkmark symbol */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 2.5px;
        border: 1.5px solid #E2E8F0;
        /* Change the color as per your preference */
        box-sizing: border-box;
        // transform: scale(1.01);

    }

    .login-terms:checked::before {
        background-color: transparent;
        /* Change the color as per your preference */
        color: white;
        /* Change the color of the checkmark when checked */
        border: none;
        /* Change the color as per your preference */

    }

    .order-table {
        .order-rdt-table {
            >div {

                // overflow-x: visible !important;
                border-radius: 0px !important;
                overflow-y: auto !important;
            }

            .dropdown-menu {
                --bs-dropdown-min-width: auto !important;
            }

            .rdt_Table {
                .rdt_TableBody {
                    >div[role="row"] {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }

    .order-status {
        >div {
            >div {
                padding-top: 0px !important;
                padding-bottom: 0px !important;
                padding-left: 5px !important;
            }
        }
    }

    .dropdown-angle-none {
        .dropdown-toggle {
            &::after {
                display: none !important;
            }
        }
    }

    .rounded-modal {

        --bs-modal-width: fit-content !important;

        .modal-content {

            border-radius: 50px;
        }
    }
}

.rest-screens {
    min-height: 510px;
}

.reset-logo {
    width: 50%;
}

.reset-done-img {
    width: 25%;
}

.reset-pages {
    min-height: 100vh;
}
.quill-editor .ql-toolbar {
    border: 1px solid #e2e4e9 !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  
  }
  
  .quill-editor .ql-container {
    height: 150px;
    border-color: #e2e4e9 !important;
    /* border: 1.5px solid #e2e4e9 !important; */
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  
  }
  
  .spinner {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.paginated-items-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    /* Adjust margin as needed */
}

@media (min-width:1040px) {
  .business_admin_main {

      .mbl-nav {
          display: none !important;
      }
  }


  .header {
      display: flex !important;
  }
}

@media (max-width:1040px) {
  .business_admin_main {

      .w-lg-80 {
          width: 100% !important;
      }

      .sidebar-desktop {
          display: none;
      }

      .mbl-nav {
          display: block !important;
          z-index: 999;
      }
  }

  .header {
      display: none !important;
  }
}

@media (max-width:992px) {
  .business_admin_main {

      .login-header {
          position: static !important;
      }

      .business-login-form {
          height: auto;


      }

      .login_height {
          min-height: auto;
      }

      .table-scroll {
          overflow-x: scroll;
      }

      .sidebar-mobile-if {
          width: 50% !important;


      }

      .datatable-show-dropdown {
          overflow-x: scroll;
      }
  }

}

@media(max-width:650px) {
  .business_admin_main {

      .linkSetting {
          border-left: none;
      }
  }
}

@media(max-width:576px) {
  .business_admin_main {

      .sidebar-mobile-if {
          width: 70% !important;


      }
  }

  .rest-screens {
      min-height: auto;
  }
}

@media(max-width:468px) {
  .reset-logo {
      width: 80%;
  }

  .reset-done-img {
      width: 40%;
  }
}
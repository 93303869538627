:root {
    --text-blue: #1D59F9;
 
  
  }
.super {


    .white-space-nowrap {
        white-space: nowrap;
    }

    .font-10 {
        font-size: 10px;
    }

    .font-11 {
        font-size: 11px;
    }

    .font-12 {
        font-size: 12px !important;
    }

    .font-13 {
        font-size: 13px !important;
    }

    .font-14 {
        font-size: 14px !important;
    }

    .font-15 {
        font-size: 15px !important;
    }

    .font-16 {
        font-size: 16px !important;
    }

    .font-17 {
        font-size: 17px !important;
    }

    .font-18 {
        font-size: 18px !important;
    }

    .font-20 {
        font-size: 20px !important;
    }

    .font-22 {
        font-size: 22px;
    }

    .font-23 {
        font-size: 23px;
    }

    .font-24 {
        font-size: 24px !important;
    }

    .font-25 {
        font-size: 25px;
    }

    .font-25 {
        font-size: 25px;
    }

    .font-26 {
        font-size: 26px;
    }

    .font-28 {
        font-size: 28px;
    }

    .font-30 {
        font-size: 30px !important;
    }

    .font-36 {
        font-size: 36px;
    }

    .font-40 {
        font-size: 40px;
    }

    .font-42 {
        font-size: 42px;
    }

    .font-48 {
        font-size: 48px;
    }

    .font-56 {
        font-size: 56px;
    }

    .font-60 {
        font-size: 60px;
    }

    .font-180 {
        font-size: 180px;
    }

    .inter {
        font-family: "Inter", sans-serif !important;
    }

    .mulish {
        font-family: "Mulish", sans-serif !important;
    }

    .poppins {
        font-family: "Poppins", sans-serif !important;
    }

    .dm {
        font-family: "DM Sans", sans-serif !important;
    }

    .roboto {
        font-family: 'Roboto', sans-serif !important;
    }

    .grey {
        color: rgb(104, 103, 108) !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .bg-tranparent {
        background-color: transparent;
    }

    // -------buttons--------
    .login-btn {
        border: 2px solid var(--text-blue);
        border-radius: 20px;
        padding: 6px 48px;
        color: var(--text-blue);
    }

    .register-btn {
        border: none;
        border-radius: 20px;
        padding: 6px 40px;
        color: var(--text-blue);
        background-color: transparent;
    }

    .register-btn:hover {
        background-color: rgba(29, 89, 249, 0.04);
    }

    .Login-btn {
        background-color: var(--text-blue);
        border: none;
        border-radius: 25px;
        color: white;
        padding: 10px;
    }

    .Qr-btn {
        background-color: var(--text-blue);
        border: none;
        color: white;
        font-size: 18px;
        font-weight: 700;
        border-radius: 30px;
        padding: 12px 44px;
    }

    .User-btn {
        background-color: var(--text-blue);
        min-width: 200px;
        padding: 12px;
        border: none;
        border-radius: 30px;
        font-size: 22px;
        font-weight: 600;
        font-family: Lato;
        color: white;
    }

    .green-btn {
        background-color: rgb(105, 223, 106);
        border-radius: 22px;
        border: none;
        font-size: 13px;
        padding: 4px 30px;
        font-weight: 700;
    }

    .cancel-btn {
        color: var(--text-blue);
        border: 2px solid var(--text-blue);
        box-shadow: none;
        background-color: #ffffff;
        padding: 12px 46px;
        border-radius: 30px;
        font-size: 18px;
        min-width: 64px;
        font-weight: 700;
    }

    .create-btn {
        background-color: var(--text-blue);
        border: none;
        border-radius: 30px;
        padding: 15px 46px;
        font-size: 18px;
        color: white;
        min-width: 64px;
        font-weight: 700;
    }

    .save-btn {
        background-color: var(--text-blue);
        border: none;
        border-radius: 30px;
        padding: 15px 65px;
        font-size: 18px;
        color: white;
        min-width: 64px;
        font-weight: 700;
    }

    .delete-btn {
        background-color: rgb(254, 66, 86);
        border: none;
        border-radius: 30px;
        padding: 15px 30px;
        font-size: 18px;
        color: white;
        min-width: 64px;
        font-weight: 700;
    }

    .upgrade-btn {
        background-color: rgba(245, 150, 254, 1);
        border: none;
        color: white;
    }

    .plan-btn {
        background-color: rgb(211, 201, 201);
        border: none;
        border-radius: 30px;
        padding: 12px 38px;
        font-size: 18px;
        color: white;
        min-width: 64px;
        font-weight: 700;
    }

    .answer-btn {
        background-color: var(--text-blue);
        border: none;
        border-radius: 30px;
        padding: 14px 30px;
        font-size: 18px;
        color: white;
        min-width: 64px;
        font-weight: 600;
    }

    .white-btn {
        border: 2px solid white;
        border-radius: 33px;
        background-color: transparent;
        font-size: 18px;
        font-weight: 600;
        padding: 12px 60px;
        color: white;
    }

    .folder-btn {
        background-color: white;
        border: 1px solid var(--text-blue);
        border-radius: 28px;
        color: var(--text-blue);
        padding: 8px 26px;
    }

    .review-btn {
        border: 2px solid white;
        border-radius: 33px;
        background-color: white;
        padding: 5px 25px;
        color: black;
    }

    .addDevice-btn {
        background-color: var(--text-blue);
        border-radius: 30px;
        padding: 13px;
        border: none;
        color: white;
    }

    .upload-btn {
        border: 2px solid var(--text-blue);
        border-radius: 22px;
        background-color: white;
        padding: 4px 16px;
    }

    .subscribe-btn {
        background-color: var(--text-blue);
        border: none;
        border-radius: 33px;
        padding: 8px 75px;
        max-width: 260px;
        color: white;
    }

    .SaveExit-btn {
        border: 1px solid red;
        border-radius: 22px;
        font-size: 16px;
        font-weight: 500;
        color: red;
        background-color: white;
    }

    .next-btn {
        background-color: var(--text-blue);
        border-radius: 22px;
        border: none;
        color: white;
        padding: 6px 50px;
    }

    .red-btn {
        background: red;
        border: none;
        border-radius: 12px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        padding: 4px;
    }

    .reward-btn {
        background-color: red;
        border: none;
        border-radius: 22px;
        padding: 4px 70px;
        color: white;
    }

    .sav-loc-btn {
        background-color: red;
        border: none;
        border-radius: 22px;
        padding: 6px;
        color: white;
    }

    .parameter-btn {
        border: none;
        background-color: lightgrey;
        padding: 10px 54px;
        color: darkgrey;
        border-radius: 33px;
        font-size: 22px;
        font-weight: 500;
    }

    .language-btn {
        border-radius: 34px;
        border: 3px solid var(--text-blue);
        padding: 12px;
        color: var(--text-blue);
        font-size: 20px;
        font-weight: 600;
    }

    .refresh-btn {
        border: none;
        background-color: var(--text-blue);
        border-radius: 22px;
        color: white;
        padding: 8px;
        font-size: 18px;
        font-weight: 600;
    }

    .AddDomain-btn {
        font-size: 18px;
        border: 3px solid var(--text-blue);
        background-color: white;
        padding: 8px;
        color: var(--text-blue);
        font-weight: 600;
        border-radius: 32px;
    }

    .back-btn {
        border: 1px solid grey;
        background-color: transparent;
        border-radius: 33px;
        padding: 8px 38px;
        font-size: 18px;
        font-weight: 600;
        color: grey;
    }

    .PDFupload-btn {
        border: none;
        background: var(--text-blue);
        border-radius: 33px;
        padding: 12px 38px;
        font-size: 20px;
        font-weight: 600;
        color: white;
    }

    .AddLink-btn {
        border: 3px solid var(--text-blue);
        border-radius: 34px;
        padding: 16px 48px;
        font-size: 22px;
        font-weight: 600;
        color: var(--text-blue);
        background-color: #f7f7f7 !important;
    }

    .preview-btn {
        border: none;
        padding: 12px 60px;
        border-radius: 35px;
        font-size: 20px;
        font-weight: 600;
        color: white;
    }

    .videoAdd-btn {
        border: none;
        border-radius: 33px;
        padding: 14px;
        color: white;
    }

    .mui-tab-btn {
        border: 3px solid lightgrey !important;
        border-radius: 8px !important;
        background-color: white !important;
        color: black !important;
    }

    .manual-btn {
        border: 2px solid var(--text-blue);
        border-radius: 28px;
        background-color: white;
        color: var(--text-blue);
        font-weight: 600;
    }

    .got-btn {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 22px;
        padding: 4px 22px;
        color: white;
    }

    .clear-btn {
        border: none;
        background-color: var(--text-blue);
        color: white;
        border-radius: 22px;
        padding: 4px 22px;
    }

    .w-lg-80 {
        width: 80% !important;
    }

    // --------------

    .lock-bg {
        background: linear-gradient(139.05deg,
                rgb(255, 205, 170) 0%,
                rgb(254, 142, 62) 49.62%,
                rgb(198, 105, 40) 100%) !important;
        width: 100% !important;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bg-purple {
        background: linear-gradient(139.05deg,
                rgb(84, 48, 92) 0%,
                rgb(34, 14, 39) 49.62%,
                rgb(22, 5, 27) 100%) !important;
        width: 100% !important;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .google-field {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        border: 2px solid #eaeaec;
        border-radius: 30px;
    }

    .form-control:focus {
        color: var(--bs-body-color);
        background-color: grey;
        border-color: #eaeaec;
        outline: 0;
        box-shadow: none !important;
    }

    .border-0 {
        border: none !important;
    }

    .google-field:hover {
        border: 2px solid var(--text-blue);
        background-color: rgba(29, 89, 249, 0.04);
    }

    .credit-lines:before {
        background-color: #cdd1e0;
        content: "";
        display: inline-block;
        height: 1px;
        width: -webkit-fill-available;
    }

    .credit-lines:after {
        background-color: #cdd1e0;
        content: "";
        display: inline-block;
        height: 1px;
        width: -webkit-fill-available;
    }

    .mail-field:hover {
        border: 1px solid grey;
        background-color: rgba(29, 89, 249, 0.04) !important;
        border-radius: 33px !important;
    }

    .radius-bor {
        border-radius: 30px !important;
    }

    video::-webkit-media-controls {
        display: none;
    }

    .Qrfy-bg {
        border: 1px solid #EAEAEA;

        background: #FFF;
        
        box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);    }

    .arrow-bg {
        top: 340px;
        left: 412px;
        width: 100px;
        height: 55px;
        position: absolute;
        // background: url(/assests/home-arrow.svg) right bottom no-repeat;
    }

    // ------accodrians-------
    .accordion-button:not(.collapsed) {
        color: var(--bs-accordion-active-color);
        background-color: transparent !important;
        box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    }

    .accordion-item {
        color: var(--bs-accordion-color);
        background-color: transparent !important;
        border: none !important;
    }

    .accordion-button:focus {
        z-index: 3;
        border-color: "";
        outline: 0;
        box-shadow: none !important;
    }

    .accordion {
        --bs-accordion-color: var(--bs-body-color);
        --bs-accordion-bg: none !important;
    }

    .green {
        color: #46cb48;
    }

    .box {
        background-color: #f8f8f9;
        padding: 33px 42px 24px 24px;
        border-radius: 8px;
    }

    .divider {
        left: 0px;
        right: 0px;
        width: 1px;
        height: 830px;
        margin: 0px auto;
        position: absolute;
        background-color: rgb(205, 209, 224);
    }

    .circle1 {
        left: -22px;
        color: rgb(255, 255, 255);
        width: 44px;
        height: 44px;
        display: flex;
        position: absolute;
        font-size: 24px;
        align-items: center;
        font-weight: 700;
        border-radius: 50%;
        justify-content: center;
        background-color: rgb(34, 14, 39);
    }

    // ---------slider-----------
    hr {
        margin: 50px 0;
    }

    .thumbnail-wrapper {
        margin-top: -350px;
        height: 85px;
        width: 90%;
        margin-left: 60px;
    }

    .thumbnail-wrapper .slick-track .slick-slide {
        text-align: center;
    }

    .thumbnail-wrapper .slick-track .slick-slide img {
        display: flex;
        margin: 0px auto;
        height: 5vh;
        width: 30%;
        border-radius: 5%;
        cursor: pointer;
    }

    // ---------------

    // .user-bg {
    //     background-color: white;
    //     border-radius: 8px;
    //     box-shadow: rgb(230, 230, 230) 0px 2px 8px;
    // }

    .inLine {
        color: #68676c;
        font-size: 13px;
        font-weight: 600;
        display: grid;
        padding-top: 4px;
        padding-bottom: 8px;
        grid-template-columns: 1fr 126px 186px 146px 220px;
    }

    // ------modal-----
    .btn-close:focus {
        outline: 0;
        box-shadow: none !important;
        opacity: var(--bs-btn-close-focus-opacity);
    }

    .modal-header {
        border-bottom: none !important;
    }

    .modal-footer {
        border-top: none !important;
    }

    .forLink {
        text-decoration: none;
        color: rgb(201, 199, 199);
    }

    .form-control:focus {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border: 2px solid var(--text-blue);
    }

    .Qr-div {
        border: 1px solid black;
        border-radius: 12px;
    }

    .loop {
        border-radius: 40px;
        border: none;
        background-color: black;
        color: white;
        position: relative;
        bottom: 220px;
        left: 355px;
    }

    .wid-hei {
        width: 10%;
        height: 10%;
    }

    .profile-upload {
        border: none;
        border-radius: 32px;
        color: white;
        background-color: grey;
        width: 6%;
        height: 6vh;
        position: relative;
        bottom: 30px;
        right: 60px;
    }

    .upload-img {
        border: 2px solid grey;
        border-radius: 6px;
    }

    .discount {
        border: none;
        background-color: var(--text-blue);
        color: white;
        border-radius: 22px;
    }

    .b-radius {
        border-radius: 8px;
    }

    .click-img-slider {
        overflow-x: scroll;

        img {
            width: 100px;
            height: 100px;
        }

        .div {
            margin: 4px;
        }

        .slick-active {
            border: 2px solid;
        }
    }

    .bg-green {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0px 10px;
        position: relative;
        align-items: flex-end;
        border-radius: 12px 0px 0px 12px;
        pointer-events: none;
        justify-content: center;
        background: linear-gradient(rgb(48, 108, 63) 0%, rgba(48, 108, 63, 0) 100%);
    }

    .nav-pills .nav-link.active {
        color: var(--bs-body-color) !important;
        background-color: var(--bs-body-bg) !important;
        border: 2px solid var(--text-blue);
        border-radius: 34px;
        padding: 12px;
    }

    .bg-mix {
        position: relative;
        background: linear-gradient(0deg,
                rgb(34, 14, 39) 15.06%,
                rgb(29, 89, 249) 100%);
        text-align: center;
        padding-top: 105px;
        border-bottom: 1px solid rgb(61, 48, 64);
        padding-bottom: 100px;
    }

    .arrow-white {
        top: 195px;
        left: 380px;
        width: 100px;
        height: 55px;
        position: absolute;
        filter: brightness(0) invert(1);
        // background: url(./assests/home-arrow.svg) right bottom no-repeat;
    }

    .white-btn:hover {
        color: var(--text-blue);
        background-color: white;
    }

    .Qrfy-scan {
        filter: brightness(0) invert(1);
        width: 55%;
        cursor: pointer;
    }

    .white {
        background-color: white;
        border-radius: 12px;
        padding: 5px;
    }

    .hand {
        cursor: pointer;
    }

    .slide-bg1 {
        display: flex;
        justify-content: center;
        background: linear-gradient(rgb(174, 108, 54) 0%, rgba(174, 108, 54, 0) 100%);
    }

    .slide-bg2 {
        display: flex;
        justify-content: center;
        background: linear-gradient(rgb(48, 108, 63) 0%, rgba(48, 108, 63, 0) 100%);
    }

    .slide-bg3 {
        display: flex;
        justify-content: center;
        background: linear-gradient(rgb(253, 223, 55) 0%, rgba(253, 223, 55, 0) 100%);
    }

    .bg-grey {
        background-color: #f8f8f9;
    }

    .slide-brown {
        position: absolute;
        top: -5px;
        left: 140px;
    }

    .slide-green {
        position: absolute;
        top: -12px;
        left: 140px;
    }

    .slide-yellow {
        position: absolute;
        top: -3px;
        left: 140px;
    }

    .carousel-control-next-icon {
        background-color: #220e27;
        border-radius: 22px;
        padding: 22px;
        position: absolute;
        left: 145px;
    }

    .carousel-control-prev-icon {
        background-color: #220e27;
        border-radius: 22px;
        padding: 22px;
        position: absolute;
        right: 145px;
    }

    .border-0 {
        border: none !important;
    }

    .export {
        border: 2px solid blue;
        border-radius: 40px;
        padding: 10px;
        background-color: white;
    }

    .b-left {
        border-left: 2px solid rgb(196, 196, 196);
    }

    .b-right {
        border-right: 2px solid rgb(196, 196, 196);
    }

    .bg-blue {
        background-color: var(--text-blue);
        color: white;
    }

    .blue {
        color: var(--text-blue) !important;
    }

    .codes {
        .nav-pills .nav-link.active {
            color: black;
            background-color: #f7f7f7 !important;
            border: none;
            border-radius: 0 !important;
            border-bottom: 2px solid var(--text-blue) !important;
            padding: 0;
        }

        .nav-link {
            padding: 0;
        }

        .right-rad {
            border-top-right-radius: 22px;
            border-bottom-right-radius: 22px;
        }

        .orange {
            color: orange;
        }

        .for-Round {
            border: 2px solid grey;
            border-radius: 22px;
            padding: 3px !important;
        }

        .recent {
            border: 1px solid lightgrey;
            border-radius: 33px;
            background-color: white;
            color: darkgrey;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .MyCodes {
        .form-select:focus {
            border-color: none;
            outline: 0;
            box-shadow: none;
        }

        .form-select {
            width: 100%;
            background-color: var(--text-blue);
            border: none;
            border-radius: 20px;
            color: white;
            padding: 7px 21px;
        }
    }

    .progress,
    .progress-stacked {
        --bs-progress-height: 0.3rem !important;
        --bs-progress-font-size: 0.75rem;
        --bs-progress-bg: var(--bs-secondary-bg);
        --bs-progress-bar-color: #fff;
        --bs-progress-bar-bg: grey !important;
    }

    .rectangle {
        border: 1px solid grey;
        border-radius: 15px;
    }

    .edit {
        border: 1px solid;
        border-radius: 22px;
        width: 60px;
    }

    .dot-Bor {
        border: 2px dashed lightgrey;
        border-radius: 12px;

        select:focus-visible {
            outline-offset: none !important;
        }
    }

    // .growth {
    //   border: 1px solid rgba(239, 240, 246, 1);
    //   border-radius: 24px;
    //   background-color: #f3f5f7;
    //   box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
    // }
    .growthEven {
        border: 1px solid rgba(239, 240, 246, 1);
        border-radius: 24px;
        background-color: #f3f5f7;
        color: black;
        box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
        transition: ease-in-out .3s;

        button {
            background-color: var(--text-blue) !important;
            color: white !important;
            font-weight: 700 !important;
            padding: 14px !important;

            border: none !important;
            font-family: "DM Sans", sans-serif !important;
        }

        .gray {
            color: #6C757D !important;
            transition: ease-in-out .3s;

        }

        .black {
            color: black;
            transition: ease-in-out .3s;

        }

        .blue {
            color: var(--text-blue) !important;
            transition: ease-in-out .3s;

        }

        &:hover {
            transform: scale(1.07);
            background-color: var(--text-blue);
            color: white;
            transition: ease-in-out .3s;

            button {
                background-color: white !important;
                color: var(--text-blue) !important;

            }

            .gray {
                color: white !important;
                transition: ease-in-out .3s;

            }

            .black {
                color: white;
                transition: ease-in-out .3s;

            }

            .blue {
                color: white !important;
                transition: ease-in-out .3s;

            }

        }
    }

    .popular {
        background-color: white;
        width: 100px;
        border-radius: 22px;
        padding: 2px;
        color: grey;
    }

    .light-grey {
        color: #e9ecefd9 !important;
    }

    .promo {
        background: #0d1efd57;
        border-radius: 22px;
        padding: 6px;
        width: 40%;
    }

    .list-group-item.active {
        color: var(--bs-list-group-active-color);
        background-color: #858585 !important;
        border-color: #858585 !important;
    }

    .mid {
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sidebar-main-div {
        box-shadow: 5px 4px 4px 0px rgba(0, 0, 0, 0.05);

        .sideNav {
            li {
                list-style: none;

                a {
                    // display: block;
                    font-weight: 500;
                    font-size: 14px !important;
                }

                a.active {
                    color: var(--text-blue) !important;
                    border-right: 4px solid;
                }
            }
        }
    }

    .loyal {
        .nav-tabs {
            --bs-nav-tabs-border-width: none !important;
            --bs-nav-tabs-border-color: none !important;
            border: 1px solid grey;
            border-radius: 32px;
        }

        link.active {
            color: var(--bs-nav-tabs-link-active-color);
            background-color: none !important;
            border-color: none !important;
        }
    }

    .radio-input input[type="radio"] {
        display: none;

        +label {
            color: green;
            font-family: Arial, sans-serif;
            font-size: 14px;
            position: relative;
            margin: 5px;

            span {
                display: flex;
                width: 16px;
                position: absolute;
                top: 15px;
                right: -8px;
                height: 16px;
                margin: -1px 4px 0 0;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 50%;
                border: 2px solid green;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
                background-repeat: no-repeat;
                background-position: center;
                text-align: center;
                line-height: 44px;
            }

            span {
                opacity: 0;
                transition: all 0.1s ease;
            }
        }

        &:checked+label span {
            opacity: 1 !important;
        }
    }

    .radio-input label span {
        background-color: green;
    }

    .bg-gray {
        background-color: #f8f9fa;
    }

    .good-btn {
        background-color: rgba(0, 160, 6, 1) !important;
        color: white !important;
        border: 0px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 0px !important;
        font-family: "inter", sans-serif !important;
    }

    .good-btn-red {
        background-color: rgba(218, 0, 0, 1) !important;
        color: white !important;
        border: 0px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 0px !important;
        font-family: "inter", sans-serif !important;
    }
    .good-btn-orange {
        background-color: rgb(252, 115, 4) !important;
        color: white !important;
        border: 0px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 0px !important;
        font-family: "inter", sans-serif !important;
    }

    .btn-blue {
        background-color: var(--text-blue) !important;
        color: white !important;
        font-weight: 700 !important;
        border: 0px !important;
        font-family: "DM Sans", sans-serif !important;
    }

    .btn-red {
        background-color: rgba(255, 61, 61, 1) !important;
        color: white !important;
        font-weight: 700 !important;
        border: 0px !important;
        font-family: "DM Sans", sans-serif !important;
    }

    .btn-orange {
        background-color: rgba(255, 153, 0, 1) !important;
        color: white !important;
        font-weight: 700 !important;
        border: 0px !important;
        font-family: "DM Sans", sans-serif !important;
    }

    .btn-green {
        background-color: rgba(0, 189, 19, 1) !important;
        color: white !important;
        font-weight: 700 !important;
        border: 0px !important;
        font-family: "DM Sans", sans-serif !important;
    }

    .btn-gray-border {
        background-color: transparent !important;
        color: rgba(29, 89, 249, 1) !important;
        border: 2px solid rgba(217, 217, 217, 1) !important;
        font-family: "Inter", sans-serif !important;
    }

    .btn-gray-bg-white {
        background-color: white !important;
        color: rgba(200, 195, 201, 1) !important;
        border: 2px solid rgba(200, 195, 201, 1) !important;
        font-family: "Inter", sans-serif !important;
    }

    .btn-black {
        background-color: rgb(0, 0, 0) !important;
        color: white !important;
        font-weight: 700 !important;
        border: 0px !important;
        font-family: "DM Sans", sans-serif !important;
    }

    .btn-gray {
        background-color: rgba(43, 43, 43, 0.1) !important;
        color: var(--text-blue) !important;
        font-weight: 700 !important;
        border: 0px !important;
        font-family: "DM Sans", sans-serif !important;
    }

    .btn-white {
        background-color: rgba(255, 255, 255, 0.993) !important;
        color: var(--text-blue) !important;
        font-weight: 700 !important;
        border: 1px solid var(--text-blue) !important;
        font-family: "Inter", sans-serif !important;
    }

    .btn-white-borderTwo {
        background-color: rgba(255, 255, 255, 0.993) !important;
        color: var(--text-blue) !important;
        font-weight: 700 !important;
        border: 2px solid var(--text-blue) !important;
        font-family: "Inter", sans-serif !important;
    }

    .btn-blue-border {
        background-color: rgba(255, 255, 255, 0.993) !important;
        color: rgba(249, 29, 29, 1) !important;
        font-weight: 700 !important;
        border: 2px solid rgba(249, 29, 29, 1) !important;
        font-family: "Inter", sans-serif !important;
    }

    .btn-green-border {
        background-color: rgba(255, 255, 255, 0.993) !important;
        color: rgba(0, 189, 19, 1) !important;
        font-weight: 700 !important;
        border: 2px solid rgba(0, 189, 19, 1) !important;
        font-family: "Inter", sans-serif !important;
    }

    .giftBox {
        border: 1px solid grey;
        border-radius: 8px;
        width: 30%;
    }

    .main-reward {
        border: none;
        background-color: lightgreen;
        border-radius: 14px;
        padding: 0px 9px;
        color: green;
        text-align: center !important;
    }

    .css-z7uhs0-MuiStepConnector-line {
        display: block;
        border-color: #dfdfdf !important;
        border-top-style: solid;
    }

    .css-j41d1b-MuiStepConnector-root.Mui-active .MuiStepConnector-line {
        border-color: red !important;
    }

    .blue-text {
        color: rgba(3, 40, 238, 1) !important;
    }

    .mini-wid {
        min-width: 15%;
    }

    .globe-img {
        border: none;
        border-radius: 12px;
        background-color: #f7f7f7;
    }

    .bor-hover {
        border: 1px solid white;

        &:hover {
            border: 1px solid orange;
        }
    }

    .top-div {
        border: 1px solid #bbbbbb;
        border-radius: 32px;
        width: 43%;
    }

    .dynamic {
        input {
            background-color: transparent;
            border: 2px solid rgb(196, 193, 193);

            &:hover {
                border: 3px solid grey;
            }

            &::placeholder {
                color: #a8a8a8;
                font-size: larger;
            }
        }
    }

    .AddDomain-btn:hover {
        background-color: #f7f7f7;
    }

    // .finance-img {
    //   transform: scale(1.15);
    //   position: relative;
    //   top: -25px;
    // }

    .blog-card-button {
        color: rgba(0, 0, 0, 1) !important;
    }

    .web:hover {
        font-weight: 600;
        border-bottom: 3px solid black;
    }

    .contact-accordin {
        .accordion-button:not(.collapsed) {
            color: var(--bs-accordion-active-color);
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }

    .offcanvas {
        a {
            font-size: 32px !important;
            font-weight: 500 !important;
        }

        a.active {
            font-size: 32px !important;
            font-weight: 700 !important;
            border-bottom: 1.5px solid rgba(0, 0, 0, 1) !important;
        }
    }

    .social-icons {
        width: 18%;
        border: 3px solid lightgrey;
        border-radius: 6px;
        padding: 6px;
    }

    .Custom-scrolll {
        .scrolll {
            overflow-y: scroll;
            height: 100vh;
        }

        .my_pics {
            width: 7%;
            border-radius: 50%;
        }

        .side-scroll::-webkit-scrollbar {
            width: 8px;
        }

        .side-scroll:hover::-webkit-scrollbar-thumb {
            background: rgb(185, 185, 185);
            border-radius: 10px;
        }

        .side-scroll {
            position: sticky;
            overflow: auto;
            height: 88vh;
        }
    }

    .home-slider {
        .slick-prev {
            left: 55px;
            z-index: 99;

            &:before {
                opacity: 3;
                color: rgb(255, 255, 255) !important;
                background-color: rgba(3, 40, 238, 1);
                padding: 9px 13px !important;
                border-radius: 50%;
                // box-shadow: -4px 4px 20px rgba(32, 180, 134, 0.12);
                content: "\f104" !important;
                font-family: "fontawesome" !important;
            }
        }

        .slick-next {
            right: 55px;
            z-index: 99;

            &:before {
                opacity: 3;
                color: rgb(255, 255, 255) !important;
                background-color: rgba(3, 40, 238, 1);
                padding: 9px 13px !important;
                border-radius: 50%;
                // box-shadow: -4px 4px 20px rgba(32, 180, 134, 0.12);
                content: "\f105" !important;
                font-family: "fontawesome" !important;
            }
        }
    }

    .Streamline-section {
        ul {
            border: 2px solid rgba(3, 40, 238, 1);
            border-radius: 50px;

            li {
                width: 50%;
            }
        }

        .nav-pills .nav-link {
            text-transform: uppercase;
            font-size: 14px !important;
            color: var(--text-blue) !important;
            background-color: transparent !important;
            padding: 15px 8px !important;
            border: none;
        }

        .nav-pills .nav-link.active {
            color: white !important;
            background-color: var(--text-blue) !important;
        }
    }

    .finance-section {
        position: relative;

        img {
            position: absolute;
            width: 400px;
            height: 360px;
            bottom: 0px;
        }
    }

    .plus-icon {
        height: 45px;
        border: 3px solid grey;
        width: 45px;
        border-radius: 4px;
        background-color: white;
    }

    .week-days {
        .form-select {
            border: 3px solid lightgray;
        }

        .mail-field :hover {
            border: 3px solid rgb(61, 61, 61) !important;
        }
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: #1976d2 !important;
        border: 3px solid #1976d2 !important;
    }

    .css-1aquho2-MuiTabs-indicator {
        position: absolute;
        height: 0 !important;
    }

    .Location-Tabs {
        .nav-pills .nav-link.active {
            color: var(--bs-body-color) !important;
            background-color: var(--bs-body-bg) !important;
            border: 3px solid var(--text-blue);
            border-radius: 6px;
            padding: 12px 60px;
        }

        .nav-pills .nav-link {
            color: grey !important;
            background-color: white !important;
            border: 3px solid lightgray;
            border-radius: 6px;
            padding: 12px 60px;
        }

        .mail-field:hover {
            border: 3px solid grey;
            background-color: white !important;
            border-radius: 33px !important;
        }
    }

    // ------togglee------
    .customCheckBoxHolder {
        margin: 5px;
        display: flex;
    }

    .customCheckBox {
        width: fit-content;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        background-color: #e7e7e7 !important;
        user-select: none;
        padding: 2px 8px;
        background-color: rgba(0, 0, 0, 0.16);
        border: 3px solid lightgray;
        border-radius: 0px;
        color: black;
        transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
        transition-duration: 300ms;
        transition-property: color, background-color, box-shadow;
        display: flex;
        height: 40px;
        align-items: center;
        outline: none;
        justify-content: center;
        min-width: 55px;
    }

    .customCheckBox .inner {
        font-size: 18px;
        font-weight: 900;
        pointer-events: none;
        transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
        transition-duration: 300ms;
        transition-property: transform;
        transform: translateY(0px);
    }

    .customCheckBox:hover .inner {
        transform: translateY(-2px);
    }

    /* Multiple Checkboxes can be chained together for a radio input */

    .customCheckBoxInput {
        display: none;
    }

    .customCheckBoxInput:checked+.customCheckBoxWrapper .customCheckBox {
        border: 3px solid var(--text-blue);
        color: black;
        background-color: white !important;
    }

    .customCheckBoxInput:checked+.customCheckBoxWrapper .customCheckBox .inner {
        transform: translateY(-2px);
    }

    .customCheckBoxWrapper .customCheckBox:hover .inner {
        transform: translateY(-2px);
    }

    .company {
        .form-control:hover {
            border: 2px solid grey !important;
        }
    }

    .Bulk-top {
        background-color: rgb(85, 172, 238);
        color: white;
        border-radius: 4px;
    }

    .download-icon {
        width: 45px;
        background-color: white;
        border: 3px solid grey;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .black-switch {
        .form-check-input:checked {
            background-color: black;
            border-color: black;
        }
    }

    .lease {
        border: 1px solid lightgrey;
        border-radius: 22px;
        color: blue;
        padding: 8px 32px;
    }

    .mui-select-field {
        position: relative;
        z-index: 99;
        div[role=combobox] {
            padding:9px 24px 9px 9px!important;
        }
        
        
        fieldset {
            border: none !important;
            font-family: "Inter", sans-serif !important;
        }

        >div {
            border: 1.5px solid rgba(200, 195, 201, 1);
            color: rgba(200, 195, 201, 1) !important;
            background-color: white;
        }

        svg {
            display: none;
        }

        &::after {
            content: "\f107";
            position: absolute;
            color: rgba(200, 195, 201, 1);
            font-family: "fontawesome" !important;
            top: 32%;
            right: 12px;
        }
    }

    .mui-select-fieldTwo {
        position: relative;

        div[role=combobox] {
            padding: 6px 8px !important;
        }

        fieldset {
            border: none !important;
            font-family: "Inter", sans-serif !important;
        }

        div[role="button"] {
            padding: 6px 10px !important;
        }

        >div {
            background-color: white;
            border: 1.5px solid rgba(200, 195, 201, 1);
            color: rgba(200, 195, 201, 1) !important;
        }

        svg {
            display: none;
        }

        &::after {
            content: "\f107";
            position: absolute;
            color: rgba(200, 195, 201, 1);
            font-family: "fontawesome" !important;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
        }
    }

    .devices-table {
        .mui-select-fieldTwo {
            position: relative;

            div[role=combobox] {
                padding: 6px 8px !important;
            }

            fieldset {
                border: none !important;
                font-family: "Inter", sans-serif !important;
            }

            div[role="button"] {
                padding: 6px 8px !important;
            }

            >div {
                background-color: white;
                border: 1.5px dashed rgba(200, 195, 201, 1);
                color: rgba(200, 195, 201, 1) !important;
            }

            svg {
                display: none;
            }

            &::after {
                content: "\f107";
                position: absolute;
                color: rgba(200, 195, 201, 1);
                font-family: "fontawesome" !important;
                top: 50%;
                right: 12px;
                transform: translateY(-50%);
            }
        }
    }

    .input-field {
        border: 1.5px solid rgba(200, 195, 201, 1) !important;
        color: rgba(200, 195, 201, 1) !important;
        resize: none;

        &::placeholder {
            color: rgba(200, 195, 201, 1) !important;
        }
    }

    .text-gray {
        color: rgba(200, 195, 201, 1) !important;
    }

    .text-dark {
        color: rgba(28, 39, 49, 1) !important;
    }

    .search-field-with-icon {
        .input-group {
            background-color: white;
            border-radius: 50px;
            border: 1.5px solid rgba(200, 195, 201, 1);
            // height: 40px;

            input {
                border-radius: 50px;

                &::placeholder {
                    color: rgba(200, 195, 201, 1);
                }
            }

            span {
                background-color: transparent;
            }
        }
    }

    .device-table {
        .deviceTableLastTD {
            height: 145px !important;
        }

        tr {

            td,
            th {
                background-color: rgb(255, 255, 255);
                color: rgba(173, 177, 181, 1) !important;
                font-weight: 600 !important;
                font-family: "Poppins", sans-serif;
                border-bottom: none !important;

                &:first-child {
                    border-top-left-radius: 15px !important;
                    border-bottom-left-radius: 15px !important;
                    color: rgba(28, 39, 49, 1) !important;
                }

                &:last-child {
                    padding: 6px !important;
                    border-top-right-radius: 15px !important;
                    position: relative;
                    border-bottom-right-radius: 15px !important;
                }
            }
        }

        input[type="checkbox"] {
            border: 2px solid red !important;
        }
    }

    .table-scroll {
        ::-webkit-scrollbar {
            width: 10px;

            height: 10px;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: rgb(192, 192, 192);
            border-radius: 10px;
        }
    }

    .table-tabs {
        .nav-pills .nav-link {
            color: rgba(200, 195, 201, 1) !important;

            background-color: transparent !important;
            border: none;
            border-radius: 0 !important;
            padding: 0;
        }

        .nav-pills .nav-link.active {
            color: black !important;
            font-weight: 500;

            border: none;
            border-bottom: none !important;
            padding: 0;

            &::after {
                content: "";
                width: -webkit-fill-available;
                height: 6px;
                background-color: var(--text-blue);
                position: relative;
                display: flex;
                top: 12px;
            }
        }
    }

    .select-search {
        div {
            div {
                fieldset {
                    border: 2px solid rgba(200, 195, 201, 1);
                    font-family: "Inter", sans-serif !important;

                    &:hover {
                        border: 2px solid rgba(200, 195, 201, 1) !important;
                    }
                }

                >div {
                    border-radius: 50px !important;
                }
            }
        }
    }

    .devices-tag-add {
        div {
            button {
                &:last-child {

                    // width: 0px !important;
                    svg {
                        display: none;
                        // position: relative;
                        // visibility: hidden;
                        // height: 0px !important;
                    }

                    &::after {
                        content: "\f107";
                        // position: absolute;
                        font-size: 17px !important;
                        display: flex;
                        color: rgba(200, 195, 201, 1);
                        font-family: "fontawesome" !important;
                        // right: 12px;
                    }
                }
            }

            div {
                fieldset {
                    border: 2px solid rgba(200, 195, 201, 1);
                    font-family: "Inter", sans-serif !important;

                    &:hover {
                        border: 2px solid rgba(200, 195, 201, 1) !important;
                        font-family: "Inter", sans-serif !important;
                    }
                }

                >div {
                    border-radius: 30px !important;
                }
            }
        }
    }

    .serial-number {
        position: relative;
        z-index: 3;

        &::before {
            width: 73px;
            height: 2px;
            content: "";
            display: inline-flex;
            background-color: rgba(200, 195, 201, 1);
            position: absolute;
            top: 50%;
            left: -44px;
            z-index: 1;
        }
    }

    .admin-text-blue {
        color: var(--text-blue) !important;
    }

    // ----------------------againChanging -----------------------
    .getStarted-bg {
        // background-image: url(./assests/GetStartedBg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 50px;
    }

    .started-field {
        input {
            background-color: rgba(255, 255, 255, 0.2);
            padding: 14px;

            &::placeholder {
                color: white;
            }
        }

        button {
            background-color: white !important;
            color: var(--text-blue) !important;
            font-weight: 700 !important;
            padding: 14px !important;

            border: none !important;
            font-family: "DM Sans", sans-serif !important;
        }
    }

    .text-dark-blue {
        color: rgba(23, 15, 73, 1);
    }

    .text-light-gray {
        color: rgba(217, 219, 233, 1);
    }

    .pricing-switch {

        /* Remove this container when use*/
        .component-title {
            width: 100%;
            position: absolute;
            z-index: 999;
            top: 30px;
            left: 0;
            padding: 0;
            margin: 0;
            font-size: 1rem;
            font-weight: 700;
            color: #888;
            text-align: center;
        }

        /* The switch - the box around the slider */
        .container-switch {
            width: 62px;
            height: 30px;
            position: relative;
        }

        /* Hide default HTML checkbox */
        .checkbox {
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;
        }

        .switch {
            width: 100%;
            height: 100%;
            display: block;
            background-color: rgba(219, 230, 255, 1);
            border-radius: 30px;
            cursor: pointer;
            transition: all 0.2s ease-out;
        }

        /* The slider */
        .slider {
            width: 25px;
            height: 25px;
            position: absolute;
            left: calc(50% - 17.5px - 10px);
            top: calc(50% - 12.8px);
            border-radius: 50%;
            background: #ffffff;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
            transition: all 0.2s ease-out;
            cursor: pointer;
        }

        .checkbox:checked+.switch {
            background-color: var(--text-blue);
        }

        .checkbox:checked+.switch .slider {
            left: calc(50% - 27px / 2 + 15.9px);
            top: calc(50% - 25.8px / 2);
        }
    }

    .saving-curve {
        position: absolute;
        bottom: 0%;
        right: -59px;
        z-index: -1;
    }

    .text-dark-blue {
        color: #0f172a !important;
    }

    .text-dak-gray {
        color: #64748b !important;
    }

    .compare-table-main {

        ::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }

        .compare-table {
            background-color: rgba(243, 245, 247, 1);
            border: 1.5px solid rgba(230, 233, 245, 1);
            overflow-x: scroll;

            table {
                background-color: transparent !important;

                thead {
                    tr {
                        th {
                            vertical-align: middle !important;
                            border-top: 0px !important;

                            &:first-child {
                                border-left: 0px !important;
                            }

                            &:last-child {
                                border-right: 0px !important;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        &:last-child {
                            td {
                                border-bottom: 0px !important;
                            }
                        }

                        td {
                            &:first-child {
                                border-left: 0px !important;
                            }

                            &:last-child {
                                border-right: 0px !important;
                            }
                        }
                    }
                }

                td,
                th {
                    vertical-align: middle !important;
                    border: 1.5px solid rgba(230, 233, 245, 1);
                }
            }

            .table> :not(caption)>*>* {
                background-color: transparent !important;
            }
        }
    }

    .btn-blue-pricing {
        background-color: var(--text-blue) !important;
        color: white !important;
        font-weight: 700 !important;
        border: 0px !important;
        font-family: "DM Sans", sans-serif !important;
    }

    .btn-blue-pricing-table {
        background-color: var(--text-blue) !important;
        color: rgba(230, 233, 245, 1) !important;
        font-weight: 700 !important;
        border: 0px !important;
        font-family: "DM Sans", sans-serif !important;
    }

    @media (max-width: 1200px) {


        .font-180 {
            font-size: 165px;
        }

        .finance-section {
            img {
                position: absolute;
                width: 450px;
                height: 400px;
                bottom: 0px;
            }
        }
    }

    @media (max-width: 1040px) {
        .font-60 {
            font-size: 55px;
        }

        .font-180 {
            font-size: 145px;
        }

        .font-48 {
            font-size: 42px;
        }


        .index_businessImages {
            height: 500px;
            object-fit: contain;
            object-position: left;
        }

        .index_marketing {
            height: 500px;
            object-fit: contain;
            object-position: right;
        }


        .w-lg-80 {
            width: 100% !important;
        }
    }

    @media (max-width: 992px) {
        .font-60 {
            font-size: 48px;
        }

        .font-48 {
            font-size: 40px;
        }

        .font-42 {
            font-size: 38px;
        }

        .font-40 {
            font-size: 36px;
        }

        .font-180 {
            font-size: 135px;
        }

        .finance-section {
            img {
                display: none !important;
            }
        }

        .index_businessImages {
            height: auto;

        }

        .index_marketing {
            height: auto;

        }
        .table-tabs {

    
            .nav-pills .nav-link.active {
                &::after {
                    content: "";
                    height: 3px;
                    top: 0px;
                }
            }
        }

        .serial-number::before {
          display: none !important;
        }
    }

    @media (max-width: 768px) {
        .font-60 {
            font-size: 42px;
        }

        .font-48 {
            font-size: 38px;
        }

        .font-42 {
            font-size: 35px;
        }

        .font-40 {
            font-size: 33px;
        }

        .w-full {
            width: 100% !important;
        }

        .font-180 {
            font-size: 126px;
        }
    }

    @media (max-width: 576px) {
        .font-60 {
            font-size: 38px;
        }

        .font-48 {
            font-size: 32px;
        }

        .font-42 {
            font-size: 28px;
        }

        .font-40 {
            font-size: 26px !important;
        }

        .font-16 {
            font-size: 14px !important;
        }

        .font-180 {
            font-size: 120px;
        }

        .font-20 {
            font-size: 18px !important;
        }

        .Streamline-section {
            ul {
                li {
                    width: 100%;
                }
            }
        }

        .home-slider {
            .slick-next {
                display: none !important;
            }

            .slick-prev {
                display: none !important;
            }
        }

        .font-56 {
            font-size: 50px;
        }
    }


    @media (max-width: 468px) {
        // .font-66 {
        //   font-size: 35px;
        // }

        // .font-42 {
        //   font-size: 26px;
        // }
        .font-56 {
            font-size: 46px;
        }
    }

    @media (max-width: 426px) {
        // .font-66 {
        //   font-size: 30px;
        // }

    }

    @media (max-width: 376px) {
        .font-42 {
            font-size: 22px;
        }

        .font-56 {
            font-size: 44px;
        }
    }

    @media (max-width: 320px) {
        .font-60 {
            font-size: 26px;
        }

        .font-42 {
            font-size: 20px;
        }
    }
}
:root {
  --text-blue: #1D59F9;
  // --primary: #2563EB;

}
.business_admin_main{
.survey-table {

  .survay-status {
    input[type='checkbox'] {
      height: 20px !important;
      width: 2.6rem !important;

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .dropdown-menu {

    --bs-dropdown-min-width: auto !important;
  }

  .dropdown-toggle::after {
    content: none !important;
  }

  div[role='table'] {
    .rdt_TableHead {
      div[role='row'] {
        div[data-column-id="1"] {
          min-width: 260px !important;
        }


      }
    }
  }

}
}
.survey-page {
  .modal-content {
    border-radius: 20px !important;
  }
}
.survey-page.modal {
  --bs-modal-width: 50% !important;
}
@media (max-width:900px){
  .survey-table-responsive{
    ::-webkit-scrollbar{
      height: 5px !important;
      width: 5px !important;
    }
    overflow-x: scroll;
    .survey-row-table{
      white-space: nowrap;
      width: 200% !important;
    }
  }
}
@media (max-width:900px){
  .survey-table-responsive{
   
    .survey-row-table{
      width: 230% !important;
    }
  }
}
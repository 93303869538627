.user-calendar {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    z-index: 12;
    box-shadow: 0px 10px 13px 0px rgba(17, 38, 146, 0.05);

    div[role="presentation"] {
        font-family: 'Inter', sans-serif !important;

    }

 

    div[role="grid"] {
        >div[role="row"] {
            span {
                font-size: 14px !important;
                font-weight: 500 !important;
                font-family: 'Inter', sans-serif !important;

                color: rgba(57, 50, 48, 1) !important;
            }
        }

        div[role="presentation"] {
            div[role="row"] {
                button {
                    font-size: 14px !important;
                    font-family: 'Inter', sans-serif !important;

                    color: rgba(171, 165, 162, 1) !important;
                }

                button.Mui-selected {
                    color: #fff !important;
                    background-color: rgba(124, 159, 251, 1) !important;
                    font-weight: 400 !important;
                }
            }
        }
    }
}